import { Timestamp, collection, deleteDoc, doc, query as firebaseQuery, getDoc, getDocs, getFirestore, limit, orderBy, setDoc, startAfter, updateDoc, where } from 'firebase/firestore'
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage"
import { ID } from '../../../../../../_metronic/helpers'
import { convertSelectToArr, getAssociatedDocs, getDateTime, simpleSubsequencesForObject } from '../../../../../../jsFunctions'
import { generatePaginationLinks, getValueByNumber } from '../../../../../../tsFunctions'
import { Client, ClientsQueryResponse } from './_models'

const collection_name = "_clients";
const API_URL = process.env.REACT_APP_THEME_API_URL
const CLIENT_URL = `${API_URL}/user`
const GET_CLIENTS_URL = `${API_URL}/users/query`
interface DynamicObject {
  [key: string]: any;
}

const getClients = (query: string): Promise<ClientsQueryResponse> => {

  const db = getFirestore()
  let params = new URLSearchParams(query);

  let sort = params.get('sort')
  let lastVisible = params.get('lastVisible')
  let page: number = Number(params.get('page'))
  let from: number = Number(params.get('from'))
  let search = params.get('search')?.toLowerCase()
  let order = params.get('order')
  let history = params.get('history')

  const promises: Promise<void>[] = []; // Array to store promises

  const parseHistoryObj = (historyString: string) => {
    if (!historyString) return [];
    return JSON.parse(historyString);
  };

  const parsedHistory = parseHistoryObj(history as string);

  let startParam = getValueByNumber(page, parsedHistory, from, lastVisible);

  if (!lastVisible && startParam.value) {
    lastVisible = startParam.value
  }

  console.log(": ", lastVisible, "\t", startParam.value);

  let q = firebaseQuery(collection(db, collection_name), limit(6));

  if (lastVisible && page > 1) {
    const counter = page - from;
    const pageLimit = 5;
    let entries = pageLimit * counter + 1;
    if (from > page) {
      let startParam = getValueByNumber(page, parsedHistory, from, lastVisible);
      if (startParam.value) {
        lastVisible = startParam.value ? startParam.value : lastVisible
      }
      if (page === startParam.key) {
        entries = pageLimit + 1;
      }
      else {
        entries = pageLimit * (page - startParam.key) + 1;
      }
    }

    return getDoc(doc(db, collection_name, lastVisible)).then((docSnap) => {
      if (sort && (order === "desc" || order === "asc") && search) {
        q = firebaseQuery(collection(db, collection_name), where("zearch", "array-contains", search), orderBy(sort, order), startAfter(docSnap), limit(entries))
      }
      else if (sort && (order === "desc" || order === "asc")) {
        q = firebaseQuery(collection(db, collection_name), orderBy(sort, order), startAfter(docSnap), limit(entries))
      }
      else if (search) {
        q = firebaseQuery(collection(db, collection_name), orderBy("dateCreated", "desc"), where("zearch", "array-contains", search), startAfter(docSnap), limit(entries))
      }
      else {
        q = firebaseQuery(collection(db, collection_name), orderBy("dateCreated", "desc"), limit(entries), startAfter(docSnap))
      }

      return new Promise(resolve =>
        getDocs(q)
          .then(querySnapshot => {
            const clientsArr = Array()

            let docs = querySnapshot.docs.slice();
            let landingPage = Math.ceil((querySnapshot.size - 1) / pageLimit);
            if (querySnapshot.size === 1) {
              landingPage = 1;
            }
            if (page > from) {
              page = from + landingPage;
            }
            //remove entries for pages that are not required
            if (landingPage > 1) {
              const toSplice = ((landingPage - 1) * pageLimit);
              docs.splice(0, toSplice);
            }
            //remove one extra entry if page is not last page
            if (docs.length > pageLimit) {
              docs.splice(-1);
              lastVisible = docs[docs.length - 1].id;
            }
            else {
              lastVisible = null;
            }

            docs.forEach(async (doc) => {
              // console.log(`${doc.id} => ${doc.data()}`)
              const user = doc.data()
              clientsArr.push({
                id: doc.id,
                dateCreated: getDateTime(user.dateCreated.seconds * 1000),
                updatedOn: user.updatedOn ? getDateTime(user.updatedOn.seconds * 1000) : getDateTime(new Date()),
                tags: user.tags,
                parent: user.parent?.label,
                info: user.info,
                deactivate: user.deactivate,
                name: user.name,
                password: user.password,
                email: user.email,
                users: [],
                activations: [],
                first_name: user.first_name ? user.first_name : "Guest",
                last_name: user.last_name ? user.last_name : "Guest",
                fullname: user.fullname ? user.fullname : "Guest",
                occupation: user.occupation ? user.occupation : "N/A",
                companyName: user.companyName ? user.companyName : "N/A",
                phone: user.companyName ? user.companyName : "N/A",
                avatar: user.avatar ? user.avatar : "",
                role: user.role,
                language: 'en',
                timeZone: user.timeZone ? user.timeZone : "N/A",
                website: user.website ? user.website : "N/A",
              })

              const parentPromise = getAssociatedDocs(user.id, "_users", "client", "==").then((users) => {
                const index_ = clientsArr.findIndex(obj => obj.id === doc.id);
                if (index_ !== -1) {
                  clientsArr[index_] = { ...clientsArr[index_], users: users };
                }
              })

              promises.push(parentPromise);

              const parentPromise2 = getAssociatedDocs(user.id, "_activations", "owner").then((activations) => {
                const index_ = clientsArr.findIndex(obj => obj.id === doc.id);
                if (index_ !== -1) {
                  clientsArr[index_] = { ...clientsArr[index_], activations: activations };
                }
              })

              promises.push(parentPromise2);
            })

            return Promise.all(promises).then(() => {


            page = clientsArr.length ? page : 1

            resolve({
              data: clientsArr,
              payload: {
                message: "N/A",
                errors: {},
                "pagination": {
                  page: page,
                  items_per_page: 10,
                  lastVisible: lastVisible,
                  "first_page_url": "/?page=1",
                  "from": 1,
                  "last_page": page,
                  "next_page_url": "\\/?page=" + (page + 1),
                  "prev_page_url": null,
                  "to": querySnapshot.docs.length + 1,
                  // "total": 21,
                  "history": { ...parsedHistory, [page]: clientsArr.length === pageLimit ? clientsArr[pageLimit - 1].id : null },
                  "links": generatePaginationLinks(page, lastVisible)
                }
              }
            })
          })
          }).catch(error => {
            console.log(error)
            resolve(error)
          }))
    })
  }
  else {
    const pageLimit = 5 * page;

    if (sort && (order === "desc" || order === "asc") && search) {
      q = firebaseQuery(collection(db, collection_name), where("zearch", "array-contains", search), orderBy(sort, order), limit(pageLimit + 1))
    }
    else if (sort && (order === "desc" || order === "asc")) {
      q = firebaseQuery(collection(db, collection_name), orderBy(sort, order), limit(pageLimit + 1))
    }
    else if (search) {
      q = firebaseQuery(collection(db, collection_name), orderBy("dateCreated", "desc"), where("zearch", "array-contains", search), limit(pageLimit + 1))
    }
    else {
      q = firebaseQuery(collection(db, collection_name), orderBy("dateCreated", "desc"), limit(pageLimit + 1))
    }

    return new Promise(resolve =>
      getDocs(q)
        .then(querySnapshot => {
          const clientsArr = Array()
          let index = 0;
          querySnapshot.forEach(async (doc) => {
            if (pageLimit > 5 && index < pageLimit - 5) {

            }
            else {
              const user = doc.data()
              clientsArr.push({
                id: doc.id,
                dateCreated: getDateTime(user.dateCreated.seconds * 1000),
                updatedOn: user.updatedOn ? getDateTime(user.updatedOn.seconds * 1000) : getDateTime(new Date()),
                tags: user.tags,
                parent: user.parent?.label,
                info: user.info,
                deactivate: user.deactivate,
                name: user.name,
                password: user.password,
                email: user.email,
                first_name: user.first_name ? user.first_name : "Guest",
                last_name: user.last_name ? user.last_name : "Guest",
                fullname: user.fullname ? user.fullname : "Guest",
                occupation: user.occupation ? user.occupation : "N/A",
                companyName: user.companyName ? user.companyName : "N/A",
                phone: user.companyName ? user.companyName : "N/A",
                users: [],
                activations: [],
                // roles: user.role ? user.role : "0",
                // avatar: user.image ? user.image : "N/A",
                avatar: user.avatar ? user.avatar : "",
                role: user.role,
                language: 'en',
                timeZone: user.timeZone ? user.timeZone : "N/A",
                website: user.website ? user.website : "N/A",
              })

              const parentPromise = getAssociatedDocs(user.id, "_users", "client", "==").then((users) => {
                const index_ = clientsArr.findIndex(obj => obj.id === doc.id);
                console.log("users ", users)
                if (index_ !== -1) {
                  clientsArr[index_] = { ...clientsArr[index_], users: users };
                }
              })

              promises.push(parentPromise);

              const parentPromise2 = getAssociatedDocs(user.id, "_activations", "owner").then((activations) => {
                const index_ = clientsArr.findIndex(obj => obj.id === doc.id);
                console.log("activations ", activations)
                if (index_ !== -1) {
                  clientsArr[index_] = { ...clientsArr[index_], activations: activations };
                }
              })

              promises.push(parentPromise2);

            }
            index++
          })

          return Promise.all(promises).then(() => {
            page = clientsArr.length ? page : 1

            lastVisible = clientsArr.length > 5 ? clientsArr[clientsArr.length - 2].id : null

            console.log("clientsArr ", clientsArr)
            console.log("lastVisible ", lastVisible)
            console.log("page  ", page)

            resolve({
              data: clientsArr.length > 5 ? clientsArr.slice(0, clientsArr.length - 1) : clientsArr,
              payload: {
                message: "N/A",
                errors: {},
                "pagination": {
                  page: page,
                  items_per_page: 10,
                  lastVisible: lastVisible,
                  "first_page_url": "/?page=1",
                  "from": 1,
                  // "last_page": 3,
                  "next_page_url": "\\/?page=" + (page + 1),
                  "prev_page_url": "\\/?page=" + (page - 1),
                  "to": querySnapshot.docs.length + 1,
                  // "total": 21,
                  "history": { ...parsedHistory, [page]: clientsArr.length > pageLimit ? clientsArr[pageLimit - 1].id : null },
                  "links": generatePaginationLinks(page, lastVisible)
                }
              }
            })
          })
        }).catch(error => {
          console.log(error)
          resolve(error)
        }))
  }
}

// const getClientById = (id: ID): Promise<Client | undefined> => {
//   const db = getFirestore()
//   let q = firebaseQuery(collection(db, collection_name))
//   q = firebaseQuery(collection(db, collection_name), where("id", "==", id))

//   return new Promise(resolve =>
//     getDocs(q)
//       .then(querySnapshot => {
//         const clientsArr = Array()
//         querySnapshot.forEach(doc => {
//           // console.log(`${doc.id} => ${doc.data()}`)
//           const user = doc.data()
//           clientsArr.push({
//             id: doc.id,
//             dateCreated: getDateTime(user.dateCreated.seconds * 1000),
//             updatedOn: user.updatedOn ? getDateTime(user.updatedOn.seconds * 1000) : getDateTime(new Date()),
//             tags: user.tags,
//             // parent: user.parent?.parentTitle,
//             info: user.info,
//             // last_login: getDateTime(user.metadata.lastSignInTime),
//             two_steps: true,
//             // joined_day: getDateTime(user.metadata.creationTime),
//             online: true,
//             initials: {
//               label: "labels",
//               state: "state",
//             },
//             name: user.name,
//             password: user.password,
//             email: user.email ? user.email : "N/A",
//             first_name: user.first_name ? user.first_name : "Guest",
//             last_name: user.last_name ? user.last_name : "Guest",
//             fullname: user.fullname ? user.fullname : "Guest",
//             occupation: user.occupation ? user.occupation : "N/A",
//             companyName: user.companyName ? user.companyName : "N/A",
//             phone: user.companyName ? user.companyName : "N/A",
//             // roles: user.role ? user.role : "0",
//             // avatar: user.image ? user.image : "N/A",
//             avatar: user.avatar ? user.avatar : "",
//             role: user.role,
//             language: 'en',
//             timeZone: user.timeZone ? user.timeZone : "N/A",
//             website: user.website ? user.website : "N/A",
//           })
//         })
//         resolve(clientsArr[0])
//       }).catch(error => {
//         console.error(error)
//         resolve(error)
//       }))
// }


const getClientById = async (id: ID): Promise<Client | undefined> => {
  const db = getFirestore();
  let q = firebaseQuery(collection(db, collection_name));
  q = firebaseQuery(collection(db, collection_name), where("id", "==", id));

  const promises: Promise<void>[] = []; // Array to store promises

  try {
    const querySnapshot = await getDocs(q);
    const clientsArr = [];

    for (const doc of querySnapshot.docs) {
      const client = doc.data();
      const clientDetails: Client = {
        id: doc.id,
        avatar: client.avatar ? client.avatar : "",
        dateCreated: getDateTime(client.dateCreated.seconds * 1000),
        updatedOn: client.updatedOn
          ? getDateTime(client.updatedOn.seconds * 1000)
          : getDateTime(new Date()),
        tags: client.tags,
        info: client.info,
        deactivate: client.deactivate,
        name: client.name,
        email: client.email ? client.email : "N/A",
        activations: [],
        collections: [],
        users: []
      };


      if (client.id) {
        // Example of an additional asynchronous operation
        // const event = await getContentByCreator(user.uid);

        const users = await getAssociatedDocs(client.id, "_users", "client", "==")
        const activations = await getAssociatedDocs(client.id, "_activations", "owner")
        const shared_activations= await getAssociatedDocs(client.id, "_activations", "clients")
        const collections = await getAssociatedDocs(client.id, "_collections", "clients")

        console.log(users)
        console.log(activations)
        console.log(collections)

        clientDetails.shared_activations = shared_activations
        clientDetails.users = users
        clientDetails.activations = activations
        clientDetails.collections = collections
      }

      promises.push(Promise.resolve()); // If you need to add any additional promises, replace this line
      clientsArr.push(clientDetails);
    }

    await Promise.all(promises); // Wait for all promises to resolve

    return clientsArr[0];
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

const uploadAndUpdate = (id: string, avatar: any, name: string) => {
  const db = getFirestore()
  const storage = getStorage()
  const storageRef = ref(storage, '/_clients/dp/' + name)
  // 'file' comes from the Blob or File API
  uploadBytes(storageRef, avatar).then((snapshot) => {
    getDownloadURL(snapshot.ref).then((downloadURL) => {
      updateDoc(doc(db, collection_name, id), {
        avatar: downloadURL,
        updatedOn: new Date()
      });

      // updateUser({ id: panelUser.id, avatar: downloadURL })
    })
  })
}

const updateClient = async (client: Client): Promise<Client | undefined> => {
  const db = getFirestore();

  if (client.id) {
    const clientDoc = doc(db, collection_name, client.id);
    const obj: DynamicObject = {};

    if (client.name) {
      obj['name'] = client.name.toLowerCase();
    }

    const tags = convertSelectToArr(client.tags);

    if (tags?.length) {
      tags.forEach((item: string) => {
        obj[item] = item;
      });
    }

    const subsequencesArray = simpleSubsequencesForObject(obj);

    try {
      await setDoc(clientDoc, {
        name: client.name,
        deactivate: client.deactivate,
        tags: convertSelectToArr(client.tags),
        info: client.info,
        zearch: subsequencesArray.concat(client.id.toLowerCase()),
        updatedOn: new Date()
      }, { merge: true });

      // Check if deactivate is false and update relevant documents in "_users" collection
      const usersCollection = collection(db, "_users");
      const query = firebaseQuery(usersCollection, where("client", "==", client.id));
      const userDocs = await getDocs(query);

      const updatePromises = userDocs.docs.map(async (userDoc) => {
        await updateDoc(userDoc.ref, { deactivate: client.deactivate });
      });

      await Promise.all(updatePromises);
      
      return client;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  } else {
    return undefined;
  }
};


const createClient = (client: Client): Promise<Client | undefined> => {

  console.log("creating client")
  
  const db = getFirestore()

  let _clients = doc(collection(db, collection_name))

  if (client.id) {
    _clients = doc(db, collection_name, client.id)
  }
  
  if (client?.avatar) {
    const storage = getStorage()
    const storageRef = ref(storage, '/_clients/dp/' + _clients.id)
    // 'file' comes from the Blob or File API
    uploadBytes(storageRef, client.avatar).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((downloadURL) => {
        updateDoc(doc(db, collection_name, _clients.id), {
          avatar: downloadURL
        });

      })
    })
  }

  const obj: DynamicObject = {};

  if (client.name) {
    obj['name'] = client.name
  }

  const tags = convertSelectToArr(client.tags);

  if (tags?.length) {
    const tags = convertSelectToArr(client.tags);
    tags.map((item: string, index: number) => {
      obj[item] = item;
    })
  }

  const subsequencesArray = simpleSubsequencesForObject(obj);

  return new Promise(resolve => setDoc(_clients, { ...client, id: _clients.id, dateCreated: Timestamp.now(), avatar: "", tags: convertSelectToArr(client.tags), zearch: subsequencesArray.concat(_clients.id.toLowerCase()) }, { merge: true })
    .then((response) => {
      console.log(response)
      resolve(client)
    }).catch(error => {
      console.log(error)
      resolve(undefined)
    }))
}


type Info = {
  collections: number;
  activation_partners: number;
  activation_owners: number
  users: number
};

const prepareForDeletion = (id: ID): Promise<Info> => {
  const db = getFirestore();

  const q = firebaseQuery(collection(db, "_collections"), where('clients', 'array-contains', id));
  const q2 = firebaseQuery(collection(db, "_activations"), where('clients', 'array-contains', id));
  const q3 = firebaseQuery(collection(db, "_activations"), where('owner', 'array-contains', id));
  const q4 = firebaseQuery(collection(db, "_users"), where('client.id', '==', id));

  const obj: Info = {
    collections: 0,
    activation_partners: 0,
    activation_owners: 0,
    users: 0
  };

  // Use Promise.all to wait for both asynchronous calls to complete
  return Promise.all([getDocs(q), getDocs(q2), getDocs(q3), getDocs(q4)])
    .then(([collectionsSnapshot, activationsSnapshot, ownersSnapshot, usersSnapshot]) => {
      obj.collections = collectionsSnapshot.size;
      obj.activation_partners = activationsSnapshot.size;
      obj.activation_owners = ownersSnapshot.size;
      obj.users = usersSnapshot.size;
      return obj;
    })
    .catch((error) => {
      console.error(error);
      throw error; // Rethrow the error to propagate it further if needed
    });
};

const archiveClient = (clientId: ID): Promise<void> => {
  const db = getFirestore();
  const collectionName = "archive" + collection_name;

  if (clientId) {
    const userDocRef = doc(db, collection_name, clientId);

    // Retrieve the document data
    return getDoc(userDocRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const userData = snapshot.data();

          // Set the document data in the new collection
          const archiveDocRef = doc(db, collectionName, clientId);
          return setDoc(archiveDocRef, userData)
            .then(() => {
              // Delete the document from the original collection
              return deleteDoc(userDocRef);
            })
            .catch((error) => {
              console.error("Error archiving user:", error);
              throw error;
            });
        } else {
          throw new Error("User document not found.");
        }
      })
      .catch((error) => {
        console.error("Error retrieving user document:", error);
        throw error;
      });
  } else {
    return Promise.reject(new Error("Invalid user ID"));
  }
}

const archiveSelectedClients = (selectedIds: Array<ID>): Promise<void> => {
  const db = getFirestore();
  const collectionName = "archive" + collection_name;

  // Use Promise.all to run the promises concurrently
  const archivePromises = selectedIds.map(documentId => {
    if (documentId) {
      const userDocRef = doc(db, collection_name, documentId);

      // Retrieve the document data
      return getDoc(userDocRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const userData = snapshot.data();

            // Set the document data in the new collection
            const archiveDocRef = doc(db, collectionName, documentId);
            return setDoc(archiveDocRef, userData)
              .then(() => {
                // Delete the document from the original collection
                return deleteDoc(userDocRef);
              })
              .catch((error) => {
                console.error("Error archiving template:", error);
                throw error;
              });
          } else {
            throw new Error(`document with ID ${documentId} not found.`);
          }
        })
        .catch((error) => {
          console.error(`Error retrieving document with ID ${documentId}:`, error);
          throw error;
        });
    } else {
      return Promise.reject(new Error("Invalid ID"));
    }
  });

  // Return a promise that resolves when all archive operations are complete
  return Promise.all(archivePromises).then(() => { });
};

export { getClients, archiveClient, archiveSelectedClients, getClientById, createClient, updateClient, uploadAndUpdate, prepareForDeletion }

