import React, { useEffect, useState } from 'react';
import { getFirestore, doc, getDoc, updateDoc, Timestamp } from 'firebase/firestore';
import { KTSVG } from '../../../helpers';
import { VersionHistoryModal } from '../../modals/version-history/version';

interface NewVersion {
  version: string;
  date: Timestamp;
  changes: string[];
}

export function VersionDrawer() {
  const [versionHistory, setVersionHistory] = useState<NewVersion[]>([]);
  const [editVersion, setEditVersion] = useState<NewVersion | null>(null);
  const db = getFirestore();

  const fetchVersionHistory = async () => {
    const versionHistoryRef = doc(db, 'stats', 'versionHistory');
    const versionHistoryDoc = await getDoc(versionHistoryRef);

    if (versionHistoryDoc.exists()) {
      const data = versionHistoryDoc.data() as { versions: NewVersion[] };
      setVersionHistory(data.versions.sort((a, b) => b.version.localeCompare(a.version, undefined, { numeric: true })));
    } else {
      console.error('Version history document not found');
    }
  };

  const convertTimestampToDate = (timestamp: Timestamp) => {
    const date = timestamp.toDate();
    
    // Format the date to dd/mm/yyyy
    return date.toLocaleDateString('en-GB'); // 'en-GB' uses the dd/mm/yyyy format
  };

  const handleEdit = (version: NewVersion) => {
    setEditVersion(version);
    openModal(); // Open modal for editing
  };

  const openModal = () => {
    const modal = document.getElementById('kt_modal_version_history');
    if (modal) {
      modal.classList.add('show');
      modal.setAttribute('aria-hidden', 'false');
      modal.style.display = 'block';
      document.body.classList.add('modal-open');
      const backdrop = document.createElement('div');
      backdrop.classList.add('modal-backdrop', 'fade', 'show');
      document.body.appendChild(backdrop);
    }
  };

  const handleCloseModal = () => {
    setEditVersion(null);
    const modal = document.getElementById('kt_modal_version_history');

    if (modal) {
      fetchVersionHistory()

      modal.classList.remove('show');
      modal.setAttribute('aria-hidden', 'true');
      modal.style.display = 'none';
      document.body.classList.remove('modal-open');
      const backdrop = document.querySelector('.modal-backdrop');
      if (backdrop) backdrop.remove();
    }
  };

 const handleDelete = async (versionToDelete: string) => {
  const confirmDeletion = window.confirm(`Are you sure you want to delete version ${versionToDelete}?`);
  if (!confirmDeletion) {
    return;
  }

  const versionHistoryRef = doc(db, 'stats', 'versionHistory');
  try {
    const versionHistoryDoc = await getDoc(versionHistoryRef);
    if (versionHistoryDoc.exists()) {
      const data = versionHistoryDoc.data() as { versions: NewVersion[] };
      
      // Filter out the deleted version and sort the updated list
      const updatedVersions = data.versions
        .filter(v => v.version !== versionToDelete)
        .sort((a, b) => b.version.localeCompare(a.version, undefined, { numeric: true }));
      
      await updateDoc(versionHistoryRef, { versions: updatedVersions });
      setVersionHistory(updatedVersions); // Update state to reflect sorted changes
    }
  } catch (error) {
    console.error('Error deleting version:', error);
  }
};

  useEffect(() => {
    fetchVersionHistory();

    const drawerToggleButton = document.getElementById('kt_versions_toggle');
    drawerToggleButton?.addEventListener('click', fetchVersionHistory);

    return () => {
      drawerToggleButton?.removeEventListener('click', fetchVersionHistory);
    };
  }, []);

  return (
    <>
      {/* Modal for Creating and Editing Versions */}
      <VersionHistoryModal
        editVersion={editVersion}
        onModalClose={handleCloseModal}
      />

      <div
        id='kt_versions'
        className='bg-body'
        data-kt-drawer='true'
        data-kt-drawer-name='explore'
        data-kt-drawer-activate='true'
        data-kt-drawer-overlay='true'
        data-kt-drawer-width="{default:'350px', 'lg': '475px'}"
        data-kt-drawer-direction='end'
        data-kt-drawer-toggle='#kt_versions_toggle'
        data-kt-drawer-close='#kt_versions_close'
      >
        <div className='card shadow-none w-100'>
          <div className='card-header'>
            <h5 className='card-title fw-bold text-gray-600'>
              Version History
            </h5>
            <div className='card-toolbar'>
              <button
                type='button'
                className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
                id='kt_versions_close'
                onClick={handleCloseModal}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
              </button>
            </div>
          </div>

          <div className='card-body'>
            <div className='scroll-y me-n5 pe-5'>
              <div className='mb-7'>
                <h3 className='mb-0'>Dashboard Versions</h3>
              </div>

              <div className='mb-0' style={{ fontFamily: 'Calibri, sans-serif' }}>
                {versionHistory.map((versionItem) => (
                  <div key={versionItem.version} className='d-flex justify-content-between align-items-start mb-3 p-2 border-bottom'>
                    <div className='flex-grow-1 me-3'>
                      <h3 className='mb-1'>{versionItem.version}</h3>
                      <span className='text-muted fs-6'>{convertTimestampToDate(versionItem.date)}</span>
                      <ul className='list-unstyled fw-semibold text-muted mt-2'>
                        {versionItem.changes.map((change, idx) => (
                          <div key={idx} className='text-dark' dangerouslySetInnerHTML={{ __html: change }} />
                        ))}
                      </ul>
                    </div>

                    <div className='d-flex align-items-center'>
                      <button className='btn btn-icon btn-sm me-2' onClick={() => handleEdit(versionItem)} aria-label="Edit version">
                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-1' />
                      </button>
                      <button className='btn btn-icon btn-sm btn-danger' onClick={() => handleDelete(versionItem.version)} aria-label="Delete version">
                        <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-1' />
                      </button>
                    </div>
                  </div>
                ))}
              </div>




            </div>
          </div>
        </div>
      </div>
    </>
  );
}
