import {ID, Response} from '../../../../../../_metronic/helpers'
export type Client = {
  id?: string
  name?: string
  avatar?: any
  email?: string
  position?: string
  parent?: any
  info?: string
  tags?: Array<string>
  dateCreated?: string
  updatedOn?: string
  startDate?: any
  deactivate?: boolean
  users?: any
  activations?: any
  collections?: any
  shared_activations?: any
}

export type ClientsQueryResponse = Response<Array<Client>>

export const initialClient: Client = {
  avatar: '',
  position: '',
  name: '',
  email: '',
  info: ''
}
