import "react-datepicker/dist/react-datepicker.css";
import clsx from 'clsx';
import { useFormik } from 'formik';
import { FC, useState } from 'react';
import DatePicker from "react-datepicker"
import CreatableSelect from 'react-select/creatable';
import * as Yup from 'yup';
import { isNotEmpty, toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import { TemplatesListLoading } from '../components/loading/TemplatesListLoading';
import { useListView } from '../core/ListViewProvider';
import { useQueryResponse } from '../core/QueryResponseProvider';
import { Template, initialTemplate } from '../core/_models';
import { updateTemplate } from '../core/_requests';
import { convertArrToSelect, RadioInput, downloadFile, dateDownloadFormat } from "../../../../../../jsFunctions";


type Props = {
  isTemplateLoading: boolean
  template: Template
}

const editTemplateSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
})


interface DropdownOptions {
  readonly value: string;
  readonly label: string;
  // readonly color: string;
  // readonly isFixed?: boolean;
  // readonly isDisabled?: boolean;
}

const dropdownOptions: readonly DropdownOptions[] = [
  { value: 'template', label: 'template' },
  { value: 'client', label: 'client' },
  { value: 'testing', label: 'testing' },
  { value: 'SAYCHEESE', label: 'SAYCHEESE' },
];

const TemplateEditModalForm: FC<Props> = ({ template, isTemplateLoading }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()

  // alert(template.disable + " " + template.dooh)

  const [templateForEdit] = useState<Template>({
    ...template,
    avatar: template.avatar || initialTemplate.avatar,
    dooh: template.dooh || initialTemplate.dooh,
    uploadAllowed: template.uploadAllowed || initialTemplate.uploadAllowed,
    microsite: template.microsite || initialTemplate.microsite,
    info: template.info || initialTemplate.info,
    name: template.name || initialTemplate.name,
    tags: template.tags ? convertArrToSelect(template.tags) : dropdownOptions
  })

  // alert(typeof templateForEdit.export)

  const [hideFields, setHideFields] = useState<boolean>(templateForEdit.dooh ? false : true);

  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    trim: true,
    matchFrom: ('start' as const) //('any' as const),
  };


  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
  const templateAvatarImg = toAbsoluteUrl(`/media/${templateForEdit.avatar}`)

  const [avatar, setAvatar] = useState<any>(templateForEdit.avatar);

  const handleChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<any> => {
    // const { target } = event;
    // const img = new Image();

    const ImageName = event.target.value.split('\\')[2];
    const Image = event.target.value;
    console.log('Imageon Kevel: ', Image); // Image on Kevel
    console.log('ImageName on Kevel: ', ImageName); // ImageName on Kevel
    console.log('ImageLink on Kevel: ', event.target.value); // ImageLink on Kevel
    // console.log('event current Target files: ', event.target?.files?[0].path);

    if (event?.target?.files?.[0]!) {
      setAvatar({ display: URL.createObjectURL(event?.target?.files?.[0]), uploadAllowed: event?.target?.files?.[0] });
    }
  };

  const formik = useFormik({
    initialValues: templateForEdit,
    validationSchema: editTemplateSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateTemplate({ ...values })
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  const handleMicrositeChange = (newValue: boolean) => {
    formik.setFieldValue('microsite', newValue);
    if (newValue) {
      formik.setFieldValue('uploadAllowed', true);
    }
  };

  const handleDOOHChange = (newValue: boolean) => {
    formik.setFieldValue('dooh', newValue);
    if (newValue) {
      formik.setFieldValue('uploadAllowed', true);
    }
  };

  const handleUploadChange = (newValue: boolean) => {
    if (newValue) {
      formik.setFieldValue('uploadAllowed', newValue);
    }
    else if (!formik.values.microsite && !formik.values.dooh) {
      formik.setFieldValue('uploadAllowed', newValue);
    }
  };

  return (
    <>
      <form id='kt_modal_add_template_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_template_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_template_header'
          data-kt-scroll-wrappers='#kt_modal_add_template_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='d-block fw-bold fs-6 mb-5'>Template Cover</label>
            {/* end::Label */}

            {/* begin::Image input */}
            <div
              // className='image-input image-input-outline'
              className='image-input'
              data-kt-image-input='true'
              style={{ backgroundImage: `url('${blankImg}')` }}
            >
              {/* begin::Preview existing avatar */}


              {templateForEdit.video ? (
                <>
                  <video
                    className='content-input-wrapper'
                    src={templateForEdit.video}
                    controls
                    controlsList="nodownload"
                    style={{
                      width: '225px',
                      height: 'auto',
                      objectFit: 'contain',
                      borderRadius: '10px 10px 10px 10px',
                    }}
                  />
                  <a
                    onClick={() => {
                      downloadFile(
                        templateForEdit.video,
                        dateDownloadFormat(templateForEdit.name) +
                        ".mp4",
                        true
                      )
                    }}
                    download={`${templateForEdit.id}.mp4`}
                    className='btn btn-icon btn-sm btn-bg-white btn-active-color-primary position-absolute top-0 end-0 m-3 mt-5'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title='Download Video'
                  >
                    <i className='bi bi-download'></i>
                  </a>
                </>
              ) : (
                <>
                  <img
                    className='image-input-wrapper'
                    src={avatar.display ? avatar.display : templateForEdit.avatar}
                    alt='Image Alt Text'
                    style={{
                      width: '125px',
                      height: 'auto',
                      objectFit: 'contain',
                      borderRadius: '10px 10px 10px 10px',
                    }}
                  />
                  <a
                    onClick={() => {
                      downloadFile(avatar.display ? avatar.display : templateForEdit.avatar, dateDownloadFormat(templateForEdit.name))
                    }}
                    download={`${templateForEdit.name}.jpg`}
                    className='btn btn-icon btn-sm btn-bg-white btn-active-color-primary position-absolute bottom-0 end-0 m-3'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title='Download Image'
                  >
                    <i className='bi bi-download'></i>
                  </a>
                </>
              )}

              {/* end::Preview existing avatar */}

              {/* begin::Cancel */}
              {/* <span
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='cancel'
              data-bs-toggle='tooltip'
              title='Cancel avatar'
            >
              <i className='bi bi-x fs-2'></i>
            </span> */}
              {/* end::Cancel */}

              {/* begin::Remove */}
              {/* <span
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='remove'
                data-bs-toggle='tooltip'
                title='Remove avatar'
              >
                <i className='bi bi-x fs-2'></i>
              </span> */}
              {/* end::Remove */}
            </div>
            {/* end::Image input */}

            {/* begin::Hint */}
            {/* <div className='form-text'>Allowed file types: png, jpg, jpeg.</div> */}
            {/* end::Hint */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Template Title</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Template Title'
              {...formik.getFieldProps('name')}
              type='text'
              name='name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.name && formik.errors.name },
                {
                  'is-valid': formik.touched.name && !formik.errors.name,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isTemplateLoading}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Date Created</label>
            {/* begin::Input */}
            <input
              placeholder='Date Created'
              aria-disabled
              {...formik.getFieldProps('dateCreated')}
              type='text'
              name='dateCreated'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.dateCreated && formik.errors.dateCreated },
                {
                  'is-valid': formik.touched.dateCreated && !formik.errors.dateCreated,
                }
              )}
              autoComplete='off'
              disabled={true}
            />
            {formik.touched.dateCreated && formik.errors.dateCreated && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.dateCreated}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}


          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Last Modified</label>
            {/* begin::Input */}
            <input
              placeholder='Last Moditied'
              aria-disabled
              {...formik.getFieldProps('updatedOn')}
              type='text'
              name='updatedOn'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.updatedOn && formik.errors.updatedOn },
                {
                  'is-valid': formik.touched.updatedOn && !formik.errors.updatedOn,
                }
              )}
              autoComplete='off'
              disabled={true}
            />
            {formik.touched.updatedOn && formik.errors.updatedOn && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.updatedOn}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}


          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Dimensions (w by h)</label>
            {/* begin::Input */}
            <input
              placeholder='Dimensions'
              aria-disabled
              {...formik.getFieldProps('dimensions')}
              type='text'
              name='dimensions'
              value={
                formik.values.dimensions
                  ? `${formik.values.dimensions.width} by ${formik.values.dimensions.height}`
                  : ''
              }
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.dimensions && formik.errors.dimensions },
                { 'is-valid': formik.touched.dimensions && !formik.errors.dimensions }
              )}
              autoComplete='off'
              disabled={true}
            />
            {formik.touched.dimensions && formik.errors.dimensions && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.dimensions}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Id</label>
            {/* begin::Input */}
            <input
              placeholder='Id'
              aria-disabled
              {...formik.getFieldProps('Id')}
              type='text'
              name='id'
              value={
                formik.values.id

              }
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.id && formik.errors.id },
                { 'is-valid': formik.touched.id && !formik.errors.id }
              )}
              autoComplete='off'
              disabled={true}
            />
            {formik.touched.id && formik.errors.id && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.id}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Tags</label>
            {/* end::Label */}

            <div
              className='px-2'
              onClick={(event) => {
                event.stopPropagation();
              }}>

              <CreatableSelect
                // value={value}
                {...formik.getFieldProps('tags')}
                placeholder="Type to Choose or Create"
                hideSelectedOptions={false}
                onChange={(newValue) => {
                  formik.setFieldValue('tags', newValue, false)
                }}
                backspaceRemovesValue={false}
                blurInputOnSelect={false}
                className='text-uppercase fs-8'
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                  },
                })}
                isMulti={true}
                styles={{
                  option: (base, state) => ({
                    ...base,
                  }),
                  multiValue: (styles, { data }) => {
                    return {
                      ...styles,
                      borderRadius: 5,
                      backgroundColor: '#432cffb8',
                      color: 'white',
                      boxShadow: '#0303030f 0px 0px 20px 0px'
                    };
                  },
                  multiValueLabel: (styles, { data }) => ({
                    ...styles,
                    fontWeight: 'bold',
                    color: 'white'

                  }),
                  multiValueRemove: (styles, { data }) => ({
                    ...styles,
                    ':hover': {
                      color: 'gray',
                    },
                  }),
                  control: (base) => ({
                    ...base,
                    // backgroundColor: var(--kt-input-solid-bg),
                    // zIndex: 9999,
                    padding: "5px",
                    border: "none",
                    borderRadius: 10,
                    boxShadow: '#3d88a614 0px 0px 20px 0px'
                    // boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px'
                    // boxShadow: "0 0 50px 0 rgb(82 63 105 / 15%)",
                  }),
                  menu: (base) => ({
                    ...base,
                    // zIndex: 19999,
                    backgroundColor: "white",
                    padding: "10px",
                    border: "none",
                    borderRadius: 10,
                    boxShadow: '#3d88a614 0px 0px 20px 0px'
                    // boxShadow: 'rgba(0, 0, 0, 0.07) -4px 9px 25px -6px'
                    // boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px',
                    // boxShadow: "0 0 50px 0 rgb(82 63 105 / 15%)",
                  }),
                  singleValue: (base) => ({ ...base, color: "#000", borderRadius: 10, }),
                }}
                // defaultValue={[dropdownOptions[2], dropdownOptions[3]]}
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                isSearchable={true}
                name="color"
                options={dropdownOptions}
              />

              {formik.touched.tags && formik.errors.tags && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.tags}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Input */}
          </div>

          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className=' fw-bold fs-6 mb-2'>Extra Info</label>
            {/* end::Label */}

            {/* begin::Input */}
            <textarea
              className='form-control form-control-solid mb-8'
              rows={3}
              placeholder='Add extra info here'
              {...formik.getFieldProps('info')}
              name='info'
              autoComplete='off'
              disabled={formik.isSubmitting || isTemplateLoading}
            ></textarea>
            {/* end::Input */}
          </div>

          <div className='mb-7'>
            <label className='required fw-bold fs-6 mb-5'>Hide on LUCY</label>
            <div className='d-flex fv-row'>
              <RadioInput
                formik={formik}
                name='disable'
                label='Yes'
                value={true}
                id='kt_modal_update_role_option_0'
                isTemplateLoading={isTemplateLoading}
              />
            </div>
            <div className='separator separator-dashed my-5'></div>
            <div className='d-flex fv-row'>
              <RadioInput
                formik={formik}
                name='disable'
                label='No'
                value={false}
                id='kt_modal_update_role_option_1'
                isTemplateLoading={isTemplateLoading}
              />
            </div>
            <div className='separator separator-dashed my-5'></div>
          </div>

          <div className='mb-7'>
            <label className='required fw-bold fs-6 mb-5'>Type</label>
            <div className='d-flex fv-row'>
              <RadioInput
                formik={formik}
                name='type'
                label='Classic'
                value={'classic'}
                id='kt_modal_update_role_option_0'
                isTemplateLoading={isTemplateLoading}
              />
            </div>
            <div className='separator separator-dashed my-5'></div>
            <div className='d-flex fv-row'>
              <RadioInput
                formik={formik}
                name='type'
                label='Magic'
                value={'magic'}
                id='kt_modal_update_role_option_1'
                isTemplateLoading={isTemplateLoading}
              />
            </div>
            <div className='separator separator-dashed my-5'></div>
            <div className='d-flex fv-row'>
              <RadioInput
                formik={formik}
                name='type'
                label='Sticker'
                value={'sticker'}
                id='kt_modal_update_role_option_1'
                isTemplateLoading={isTemplateLoading}
              />
            </div>
            <div className='separator separator-dashed my-5'></div>
          </div>

          <div className='mb-7 d-flex justify-content-between align-items-center'>
            <div className='fw-bold fs-6.25'>Storywall</div>
            <div className='form-check form-check-custom form-check-solid form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                id='storywallSwitch'
                checked={formik.values.microsite}
                onChange={() => handleMicrositeChange(!formik.values.microsite)}
                // onChange={() => formik.setFieldValue('microsite', !formik.values.microsite)}
                disabled={isTemplateLoading || formik.values.type === 'sticker'}
              />
            </div>
          </div>


          <div className='mb-7 d-flex justify-content-between align-items-center'>
            <div className='fw-bold fs-6.25'>DOOH</div>
            <div className='form-check form-check-custom form-check-solid form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                id='doohSwitch'
                checked={formik.values.dooh}
                onChange={() => handleDOOHChange(!formik.values.dooh)}
                // onChange={() => formik.setFieldValue('dooh', !formik.values.dooh)}
                disabled={isTemplateLoading}
              />
            </div>
          </div>


          <div className='mb-7 d-flex justify-content-between align-items-center'>
            <div className='fw-bold fs-6.25'>Print</div>
            <div className='form-check form-check-custom form-check-solid form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                id='printSwitch'
                checked={formik.values.print}
                onChange={() => formik.setFieldValue('print', !formik.values.print)}
                disabled={isTemplateLoading || formik.values.type === 'sticker'}
              />
            </div>
          </div>

          <div className='mb-7 d-flex justify-content-between align-items-center'>
            <div className='fw-bold fs-6.25'>Upload to Dashboard</div>
            <div className='form-check form-check-custom form-check-solid form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                id='uploadAllowedSwitch'
                checked={formik.values.uploadAllowed}
                onChange={() => handleUploadChange(!formik.values.uploadAllowed)}
                // onChange={() => formik.setFieldValue('uploadAllowed', !formik.values.uploadAllowed)}
                disabled={isTemplateLoading || formik.values.microsite || formik.values.dooh}
              />
            </div>
          </div>
          {/* begin::Input group */}

          {/* end::Input group */}
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-templates-modal-action='cancel'
            disabled={formik.isSubmitting || isTemplateLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-templates-modal-action='submit'
            disabled={isTemplateLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isTemplateLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form >
      {(formik.isSubmitting || isTemplateLoading) && <TemplatesListLoading />
      }
    </>
  )
}

export { TemplateEditModalForm };

