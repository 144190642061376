// import React, { useEffect } from 'react';

// const FlookWidget = () => {
//   useEffect(() => {
//     // Create script element
//     const script = document.createElement('script');
//     script.src = 'https://app.flook.com/widget.js';  // Use the widget URL
//     script.async = true; // Load asynchronously

//     // Append the script to the body or specific container
//     document.body.appendChild(script);

//     // Clean up on unmount (optional)
//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []); // Empty dependency array ensures this runs once after component mounts

//   return (
//     <div style={{height: "100%", width: 100, backgroundColor: 'red'}}>
//       <div id="widget-container"></div> {/* Ensure you have a container for the widget */}
//     </div>
//   );
// };

// export default FlookWidget;

import React, { useEffect } from 'react';

const FlookWidget = () => {
  useEffect(() => {
    // Create and append the widget script
    const script = document.createElement('script');
    script.src = 'https://app.flook.com/widget.js';  // Use the script URL from Flook
    script.async = true;

    // Append the script to the document
    document.body.appendChild(script);

    // Optional cleanup on unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []); // The empty array ensures this runs only once after the component mounts

  return null; // No manual container needed if the script handles the UI
};

export default FlookWidget;
