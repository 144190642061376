import React, { FC, useState, useEffect } from 'react';
import { KTSVG } from '../../../helpers';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { getFirestore, doc, updateDoc, setDoc, Timestamp, getDoc } from 'firebase/firestore';

interface NewVersion {
  version: string;
  date: Timestamp;
  changes: string[];
}

const VersionHistoryModal: FC<{ editVersion?: NewVersion | null, onModalClose?: () => void }> = ({ editVersion, onModalClose }) => {
  const [version, setVersion] = useState<string>(''); 
  const [changes, setChanges] = useState<string>(''); 
  const db = getFirestore();

  useEffect(() => {
    if (editVersion) {
      setVersion(editVersion.version);
      setChanges(editVersion.changes.join('\n'));
    } else {
      resetForm();
    }
  }, [editVersion]);

  const saveVersionInfo = async () => {
    if (!version || !changes) {
      console.error('Version or Changes are missing');
      return;
    }

    const versionHistoryRef = doc(db, 'stats', 'versionHistory');
    const newVersion: NewVersion = {
      version,
      date: Timestamp.now(),
      changes: changes.split('\n'),
    };

    try {
      const versionHistoryDoc = await getDoc(versionHistoryRef);
      if (versionHistoryDoc.exists()) {
        const versionData = versionHistoryDoc.data() as { versions: NewVersion[] };

        const updatedVersions = editVersion
          ? versionData.versions.map((v) => (v.version === editVersion.version ? newVersion : v))
          : [...versionData.versions, newVersion];

        await updateDoc(versionHistoryRef, { versions: updatedVersions });
      } else {
        await setDoc(versionHistoryRef, { versions: [newVersion] });
      }

      closeModal();

    } catch (error) {
      console.error('Error updating version history:', error);
    }
  }

  const closeModal = () => {
    if (onModalClose) onModalClose(); 
    resetForm(); 
  }

  const resetForm = () => {
    setVersion('');
    setChanges('');
  }

  return (
    <div className='modal fade' id='kt_modal_version_history' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-between'>
            <h2 className='fw-bolder text-center w-100'>
              {editVersion ? 'Edit Version' : 'Create New Version'}
            </h2>
            <button 
              type='button' 
              className='btn btn-sm btn-icon btn-active-light-primary' 
              onClick={closeModal}
              aria-label="Close"
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
            </button>
          </div>
          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='mb-4'>
              <label htmlFor='version' className='form-label fw-bold'>Version Number</label>
              <input 
                type='text' 
                className='form-control form-control-solid' 
                id='version' 
                placeholder='e.g. 1.0.0'
                value={version} 
                onChange={(e) => setVersion(e.target.value)} 
              />
            </div>
            <div className='mb-4'>
              <label className='form-label fw-bold'>Changes</label>
              <ReactQuill 
                value={changes} 
                onChange={setChanges} 
                theme='snow'
                placeholder='List the changes here...' 
                style={{ height: '250px' }} // Adjust height as necessary
              />
            </div>
          </div>
          <div className='modal-footer justify-content-center'>
            <button 
              type='button' 
              className='btn btn-light me-3' 
              onClick={closeModal}
            >
              Cancel
            </button>
            <button 
              type='button' 
              className='btn btn-primary' 
              onClick={saveVersionInfo}
            >
              {editVersion ? 'Update Version' : 'Save Version'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// Global style for React Quill editor
const quillStyles = `
  .ql-editor {
    font-family: 'Calibri', sans-serif; /* Set the font to Calibri */
    color: #333; /* Darker text color */
  }
  .ql-toolbar {
    font-family: 'Calibri', sans-serif; /* Set toolbar font to Calibri */
  }
`;

// Injecting styles to the head of the document
const style = document.createElement('style');
style.innerHTML = quillStyles;
document.head.appendChild(style);

export { VersionHistoryModal };
