/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx';
import { FC } from 'react';
import { toAbsoluteUrl } from '../../../../../../../_metronic/helpers';
import { navigateToModal } from '../../../../../../../jsFunctions'

type Props = {
  parent: Array<any>;
  collection?: Boolean
};

const ParentInfoCell: FC<Props> = ({ parent, collection }) => {
  if (!parent || !Array.isArray(parent) || parent.length === 0) {
    console.log("No parent data available");
    return null; // Return null or handle this case appropriately
  }
  return (
    <div>
      {parent.map((item, index) => (
        <div className={`d-flex align-items-center ${item.archive ? 'archive-item' : ''}`} key={index} style={{ marginBottom: '10px' }}
        onClick={() => collection ? navigateToModal("campaign", item.id): navigateToModal("client", item.id)}
        >
          {/* begin:: Avatar */}
          <div className='symbol symbol-oval symbol-30px overflow-hidden me-3'>
            <a href='#'>
              {item?.avatar ? (
                <div className='symbol-label'>
                  <img
                    src={item?.avatar}
                    alt={item?.label[0]}
                    className='w-100'
                    onError={(e) => {
                      const target = e.target as HTMLImageElement;
                      target.onerror = null; // prevent infinite loop
                      target.src = toAbsoluteUrl('/media/svg/files/blank-image.svg'); // replace with a fallback image URL
                      target.alt = item?.label[0]; // set alt text
                    }}
                  />
                </div>
              ) : (
                <div
                  className={clsx(
                    'symbol-label fs-3',
                    `bg-light-${item?.label}`,
                    `text-${item?.label}`
                  )}
                >
                  {item?.label?.[0]}
                </div>
              )}
            </a>
          </div>
          <div className='d-flex flex-column'>
            <div
              className='text-gray-500 text-hover-primary fw-normal'
              style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              {item?.label}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export { ParentInfoCell };
