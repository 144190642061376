import React, { FC } from 'react';

const VersionsToggleDrawer: FC = () => (
  <button
    id='kt_versions_toggle'  // Ensure this ID matches with the drawer toggle
    className='engage-version-toggle btn btn-flex h-35px bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm px-5 rounded-top-0'
    title='View Version History' // Updated title for clarity
    data-bs-toggle='tooltip'
    data-bs-placement='left'
    data-bs-dismiss='click'
    data-bs-trigger='hover'
  >
    Version History
  </button>
);

export { VersionsToggleDrawer };
