import React, { FC, useState, useEffect } from 'react'
import CreatableSelect from 'react-select/creatable';
import * as Yup from 'yup'
import { useFormik } from 'formik'
import ReactSelect from 'react-select';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { isNotEmpty, toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { initialEvent, Event } from '../core/_models'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { EventsListLoading } from '../components/loading/EventsListLoading'
import { createEvent, updateEvent, uploadAndUpdate } from '../core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { convertArrToSelect, containsId } from '../../../../../../jsFunctions';
import { RadioInput, addDaysToDate, removeSpaces, copyToClipboard } from '../../../../../../jsFunctions';
// import { getDateTime } from '../../../../../../jsFunctions';
type Props = {
  isEventLoading: boolean
  event: Event
  clients: DropdownOptions[]
  memoryMakers: DropdownOptions[]
  templates: DropdownOptions[]
}

const editEventSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
  startDate: Yup.mixed().notOneOf([null], 'Start Date is required').required('Start Date is required'),
  endDate: Yup.mixed().notOneOf([null], 'End Date is required').required('End Date is required'),
})


interface DropdownOptions {
  readonly value: string;
  readonly image?: string;
  readonly label: string;
}

const dropdownOptions: readonly DropdownOptions[] = [
  { value: 'event', label: 'event' },
  { value: 'client', label: 'client' },
  { value: 'testing', label: 'testing' },
  { value: 'SAYCHEESE', label: 'SAYCHEESE' },
];

const EventEditModalForm: FC<Props> = ({ event, isEventLoading, clients, memoryMakers, templates }) => {
  const { setItemIdForUpdate } = useListView()

  const { refetch } = useQueryResponse()

  const [eventForEdit] = useState<Event>({
    ...event,
    avatar: event.avatar || initialEvent.avatar,
    info: event.info || initialEvent.info,
    name: event.name || initialEvent.name,
    parent: event.parent || initialEvent.parent,
    sendgrid: event.sendgrid?.length ? event.sendgrid : 'd-26fae6d8ec634424a738c8826c53d2b2',
    sendgridDooh: event.sendgridDooh?.length ? event.sendgridDooh : 'd-26fae6d8ec634424a738c8826c53d2b2',
    // form:("https://yourstorywall.com/content/FRM_" + event.projectNo + "_" + removeSpaces(event.parent[0].label) + "_" + event.startDate.toISOString().slice(0, -14)).toLocaleLowerCase(),
    tags: event.tags ? convertArrToSelect(event.tags) : dropdownOptions
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
  const eventAvatarImg = toAbsoluteUrl(`/media/${eventForEdit.avatar}`)
  const [avatar, setAvatar] = useState<any>(eventForEdit.avatar);
  const [startDate, setStartDate] = useState<any>(eventForEdit.startDate);
  const [endDate, setEndDate] = useState<any>(eventForEdit.endDate);
  const handleChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<any> => {
    // const { target } = event;
    // const img = new Image();

    const ImageName = event.target.value.split('\\')[2];
    const Image = event.target.value;
    console.log('Imageon Kevel: ', Image); // Image on Kevel
    console.log('ImageName on Kevel: ', ImageName); // ImageName on Kevel
    console.log('ImageLink on Kevel: ', event.target.value); // ImageLink on Kevel
    console.log('event current Target files: ', event.target?.files?.[0]);

    if (event?.target?.files?.[0]!) {
      setAvatar({ display: URL.createObjectURL(event?.target?.files?.[0]), upload: event?.target?.files?.[0] });
    }
  };

  const formik = useFormik({
    initialValues: eventForEdit,
    validationSchema: editEventSchema,
    onSubmit: async (values, { setSubmitting }) => {

      console.log("onSubmit ", event)

      // alert(values.micrositeDate)

      setSubmitting(true)

      try {
        if (isNotEmpty(values.id)) {
          await updateEvent({ ...values })
          if (avatar.upload && values.id) {
            uploadAndUpdate(values.id, avatar.upload, values.id)
          }
        } else {
          await createEvent({ ...values, avatar: avatar.upload })
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })


  useEffect(() => {
    // Function to update the form value based on projectNo and startDate
    const updateFormValue = () => {
      const form = `https://yourstorywall.com/content/FRM-${formik.values.projectNo}-${removeSpaces(formik.values.parent[0].label, "-")}-${formik.values.startDate.toISOString().slice(0, -14)}`.toLocaleLowerCase();
      const micrositeBeta = `https://yourstorywall.com/content/DEV-${formik.values.projectNo}-${removeSpaces(formik.values.parent[0].label, "-")}-${formik.values.startDate.toISOString().slice(0, -14)}`.toLocaleLowerCase();
      const microsite = `SW_${formik.values.projectNo}_${formik.values.startDate.toISOString().slice(0, -14)}`.toLocaleLowerCase();
      const exportFolder = `SC_Export_${formik.values.projectNo}_${formik.values.startDate.toISOString().slice(0, -14)}`.toLocaleLowerCase();

      const dooh = `https://yourstorywall.com/content/DOOH-${formik.values.projectNo}-${formik.values.startDate.toISOString().slice(0, -14)}`.toLocaleLowerCase();

      const quitclaim = `https://yourstorywall.com/content/QC-${formik.values.projectNo}-${formik.values.startDate.toISOString().slice(0, -14)}`.toLocaleLowerCase();

      const intro = `https://yourstorywall.com/content/INFO-${formik.values.projectNo}-${formik.values.startDate.toISOString().slice(0, -14)}`.toLocaleLowerCase();

      if (formik.values.isIntro && !eventForEdit.intro) {
        formik.setFieldValue('intro', intro);
      }

      if (formik.values.isMicrosite && !eventForEdit.microsite) {
        formik.setFieldValue('microsite', microsite);
      }

      if (formik.values.isMicrosite && !eventForEdit.micrositeDate) {
        formik.setFieldValue('micrositeDate', startDate);
      }

      if (formik.values.isMicrosite && !eventForEdit.micrositeName) {
        formik.setFieldValue('micrositeName', formik.values.name);
      }

      if (formik.values.isMicrosite && !eventForEdit.micrositeBeta) {
        formik.setFieldValue('micrositeBeta', micrositeBeta);
      }

      if (formik.values.isForm && !eventForEdit.form) {
        formik.setFieldValue('form', form);
      }

      if (!eventForEdit.exportFolder) {
        formik.setFieldValue('exportFolder', exportFolder);
      }

      if (formik.values.isDooh && !eventForEdit.dooh) {
        formik.setFieldValue('dooh', dooh);
      }

      if (formik.values.isDooh && !eventForEdit.quitclaim) {
        formik.setFieldValue('quitclaim', quitclaim);
      }

    };

    // alert(formik.values.isRouter + " " + formik.values.isMicrosite)

    // Initial update when the component mounts
    updateFormValue();
  }, [formik.values.projectNo, formik.values.startDate, formik.values.name, formik.values.isDooh, formik.values.isMail, formik.values.isForm, formik.values.isMicrosite, formik.values.isIntro]); // Include dependencies if needed

  return (
    <>
      <form id='kt_modal_add_event_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_event_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_event_header'
          data-kt-scroll-wrappers='#kt_modal_add_event_scroll'
          data-kt-scroll-offset='300px'
        >

          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Activation Id</label>
            {/* end::Label */}

            {/* begin::Input */}
            <div className='input-group' style={{ zIndex: 0 }}>
              <input
                placeholder='id'
                aria-disabled
                disabled={true}
                {...formik.getFieldProps('id')}
                type='text'
                name='id'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.id && formik.errors.id },
                  { 'is-valid': formik.touched.id && !formik.errors.id }
                )}
                autoComplete='off'
              />
              <button
                className='btn btn-primary'
                type='button'
                disabled={formik.isSubmitting || isEventLoading}
                onClick={() => {
                  copyToClipboard(formik.values.id)
                }}
              >
                Copy
              </button>
            </div>
            {/* end::Input */}

            {formik.touched.id && formik.errors.id && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.id}</span>
                </div>
              </div>
            )}
          </div>

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='d-block fw-bold fs-6 mb-5'>Avatar</label>
            {/* end::Label */}

            {/* begin::Image input */}
            <div
              // className='image-input image-input-outline'
              className='image-input'
              data-kt-image-input='true'
              style={{ backgroundImage: `url('${blankImg}')` }}
            >
              {/* begin::Preview existing avatar */}
              {/* <div
                className='image-input-wrapper w-125px h-125px'
                style={{ backgroundImage: `url('${avatar.display ? avatar.display : eventForEdit.avatar}')` }}
              ></div> */}
              <img
                className='image-input-wrapper'
                src={avatar.display ? avatar.display : eventForEdit.avatar ? eventForEdit.avatar : toAbsoluteUrl('/media/svg/files/blank-image-dark.svg')}
                alt='Image Alt Text'
                style={{
                  width: '100%',
                  height: '150px',
                }}
              />
              {/* end::Preview existing avatar */}

              {/* begin::Label */}
              <label
                className='btn btn-icon  btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='change'
                data-bs-toggle='tooltip'
                title='Change avatar'
              >
                <i className='bi bi-pencil-fill fs-7'></i>

                <input type='file'
                  onChange={handleChange}
                  name='avatar' accept='.png, .jpg, .jpeg' />
                {/* <input type='hidden' name='avatar_remove' /> */}
              </label>
              {/* end::Label */}
            </div>
            {/* end::Image input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Activation Title</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Activation Title'
              {...formik.getFieldProps('name')}
              type='text'
              name='name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.name && formik.errors.name },
                {
                  'is-valid': formik.touched.name && !formik.errors.name,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isEventLoading}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}


          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-2'>Project #</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Project #'
              {...formik.getFieldProps('projectNo')}
              type='text'
              name='projectNo'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isEventLoading}
            />
            {/* end::Input */}
          </div>

          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className=' fw-bold fs-6 mb-2'>Tags</label>
            {/* end::Label */}

            {/* begin::Input */}
            <div
              className='px-2'
              onClick={(event) => {
                event.stopPropagation();
              }}>

              <CreatableSelect
                // value={value}
                {...formik.getFieldProps('tags')}
                placeholder="Type to Choose or Create"
                hideSelectedOptions={false}
                onChange={(newValue) => {
                  formik.setFieldValue('tags', newValue, false)
                }}
                backspaceRemovesValue={false}
                blurInputOnSelect={false}
                className='fs-6'
                // text-uppercase 
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                  },
                })}
                isMulti={true}
                styles={{
                  option: (base, state) => ({
                    ...base,
                  }),
                  multiValue: (styles, { data }) => {
                    return {
                      ...styles,
                      borderRadius: 10,
                      backgroundColor: '#432cffb8',
                      padding: 4,
                      margin: 4,
                      color: 'white',
                      boxShadow: '#0303030f 0px 0px 20px 0px'
                    };
                  },
                  multiValueLabel: (styles, { data }) => ({
                    ...styles,
                    fontWeight: 'bold',
                    color: 'white'

                  }),
                  multiValueRemove: (styles, { data }) => ({
                    ...styles,
                    ':hover': {
                      color: 'gray',
                    },
                  }),
                  control: (base) => ({
                    ...base,
                    // backgroundColor: var(--kt-input-solid-bg),
                    // zIndex: 9999,
                    padding: "5px",
                    border: "none",
                    borderRadius: 10,
                    boxShadow: '#3d88a614 0px 0px 20px 0px'
                    // boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px'
                    // boxShadow: "0 0 50px 0 rgb(82 63 105 / 15%)",
                  }),
                  menu: (base) => ({
                    ...base,
                    // zIndex: 19999,
                    backgroundColor: "white",
                    padding: "10px",
                    border: "none",
                    borderRadius: 10,
                    boxShadow: '#3d88a614 0px 0px 20px 0px'
                    // boxShadow: 'rgba(0, 0, 0, 0.07) -4px 9px 25px -6px'
                    // boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px',
                    // boxShadow: "0 0 50px 0 rgb(82 63 105 / 15%)",
                  }),
                  singleValue: (base) => ({ ...base, color: "#000", borderRadius: 10, }),
                }}
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                isSearchable={true}
                name="color"
                options={dropdownOptions}
              />
              {formik.touched.tags && formik.errors.tags && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.tags}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Input */}
          </div>


          {/* begin::Input group */}
          <div className='fv-row mb-0'>
            {/* begin::Label */}
            <label className=' fw-bold fs-6 mb-2'>Extra Info</label>
            {/* end::Label */}

            {/* begin::Input */}
            <textarea
              className='form-control form-control-solid mb-8'
              rows={3}
              placeholder='Add extra info here'
              {...formik.getFieldProps('info')}
              name='info'
              autoComplete='off'
              disabled={formik.isSubmitting || isEventLoading}
            ></textarea>
            {/* end::Input */}
          </div>
          {/* end::Input group */}


          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Start Date</label>
            <DatePicker showPopperArrow={false}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.startDate && formik.errors.startDate },
                {
                  'is-valid': formik.touched.startDate && !formik.errors.startDate,
                }
              )}
              // minDate={new Date()}
              selected={formik.values.startDate}
              onChange={(startDate) => {
                formik.setFieldValue('startDate', startDate, false)
                if (formik.values.isMicrosite) {
                  formik.setFieldValue('micrositeDate', startDate, false)
                }
                setStartDate(startDate)
                formik.setFieldValue('endDate', addDaysToDate(startDate, 1))
                setEndDate(addDaysToDate(startDate, 1))
              }}
              showTimeSelect={true}
              timeFormat="HH:mm"
              dateFormat={"HH:mm MMMM d, yyyy"}
            />
            {/* {formik.touched.startDate && formik.errors.startDate && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.startDate}</span>
              </div>
            )} */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>End Date</label>
            <DatePicker
              showPopperArrow={false}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.endDate && formik.errors.endDate },
                {
                  'is-valid': formik.touched.endDate && !formik.errors.endDate,
                }
              )}
              minDate={formik.values.startDate}
              selected={formik.values.endDate}
              onChange={(endDate) => {
                formik.setFieldValue('endDate', endDate)
                setEndDate(endDate)
              }}
              showTimeSelect={true}
              maxDate={addDaysToDate(formik.values.startDate, 1)}
              timeFormat="HH:mm"
              dateFormat={"HH:mm MMMM d, yyyy"}
            />
          </div>
          {/* end::Input group */}

          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className=' fw-bold fs-6 mb-2'>Owner</label>
            {/* end::Label */}

            {/* begin::Input */}
            <div
              className='px-2'
              onClick={(event) => {
                event.stopPropagation();
              }}>

              <ReactSelect
                // value={value}
                {...formik.getFieldProps('parent')}
                placeholder="Tap or Search"
                hideSelectedOptions={false}
                // onChange={(newValue) => {
                //   formik.setFieldValue('parent', newValue, false)
                // }}
                filterOption={(option, rawInput) => {
                  let searchArray = [...option.data.tags];  // Use the spread operator to clone the array
                  searchArray.push(option.label.toLowerCase());
                  searchArray.push(option.value.toLowerCase());

                  const matchingStrings = searchArray.filter((str) => str.includes(rawInput));
                  return matchingStrings.length > 0;  // Simplified the return statement
                }
                }
                backspaceRemovesValue={false}
                blurInputOnSelect={false}
                className=' fs-6'
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                  },
                })}
                isMulti={true}
                styles={{
                  option: (base, state) => ({
                    ...base,
                  }),
                  multiValue: (styles, { data }) => {
                    return {
                      ...styles,
                      borderRadius: 10,
                      backgroundColor: data.archive ? '#7239ea73  ' : '#432cffb8',
                      padding: 4,
                      margin: 4,
                      color: 'white',
                      boxShadow: '#0303030f 0px 0px 20px 0px'
                    };
                  },
                  multiValueLabel: (styles, { data }) => ({
                    ...styles,
                    fontWeight: 'bold',
                    color: 'white'

                  }),
                  multiValueRemove: (styles, { data }) => ({
                    ...styles,
                    ':hover': {
                      color: 'gray',
                    },
                  }),
                  control: (base) => ({
                    ...base,
                    // backgroundColor: var(--kt-input-solid-bg),
                    // zIndex: 9999,
                    padding: "5px",
                    border: "none",
                    borderRadius: 10,
                    boxShadow: '#3d88a614 0px 0px 20px 0px'
                    // boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px'
                    // boxShadow: "0 0 50px 0 rgb(82 63 105 / 15%)",
                  }),
                  menu: (base) => ({
                    ...base,
                    // zIndex: 19999,
                    backgroundColor: "white",
                    padding: "10px",
                    border: "none",
                    borderRadius: 10,
                    boxShadow: '#3d88a614 0px 0px 20px 0px'
                    // boxShadow: 'rgba(0, 0, 0, 0.07) -4px 9px 25px -6px'
                    // boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px',
                    // boxShadow: "0 0 50px 0 rgb(82 63 105 / 15%)",
                  }),
                  singleValue: (base) => ({ ...base, color: "#000", borderRadius: 10, }),
                }}

                formatOptionLabel={(option, { selectValue }) => {
                  const isSelected = selectValue && selectValue.some((selectedOption) => selectedOption.value === option.value);

                  return (
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-oval symbol-40px overflow-hidden me-3'>
                        <a href='#'>
                          {option.avatar ? (
                            <div className='symbol-label'>
                              <img src={option.avatar} alt={option.label} className='w-100' />
                            </div>
                          ) : (
                            <div
                              className={clsx(
                                'symbol-label fs-3',
                                `bg-light-${option.label}`,
                                `text-${option.label}`
                              )}
                            >
                              {option.label?.[0]}
                            </div>
                          )}
                        </a>
                      </div>
                      <div className='d-flex flex-column'>
                        <a href='#' className={isSelected ? 'text-white' : 'text-gray-800 text-hover-primary mb-1'}>
                          {option.label}
                        </a>
                        <span style={{ color: 'black' }}>{option.email}</span>
                      </div>
                    </div>
                  );
                }}
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                isSearchable={true}
                name="color"
              />
              {formik.touched.tags && formik.errors.tags && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.tags}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Input */}
          </div>

          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className=' fw-bold fs-6 mb-2'>Shared Clients</label>
            {/* end::Label */}

            {/* begin::Input */}
            <div
              className='px-2'
              onClick={(event) => {
                event.stopPropagation();
              }}>

              <ReactSelect
                // value={value}
                {...formik.getFieldProps('clients')}
                placeholder="Tap or Search"
                hideSelectedOptions={false}
                onChange={(newValue) => {
                  formik.setFieldValue('clients', newValue, false)
                }}
                filterOption={(option, rawInput) => {
                  let searchArray = [...option.data.tags];  // Use the spread operator to clone the array
                  searchArray.push(option.label.toLowerCase());
                  searchArray.push(option.value.toLowerCase());

                  const matchingStrings = searchArray.filter((str) => str.includes(rawInput));
                  return matchingStrings.length > 0;  // Simplified the return statement
                }
                }
                backspaceRemovesValue={false}
                blurInputOnSelect={false}
                className=' fs-6'
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                  },
                })}
                isMulti={true}
                styles={{
                  option: (base, state) => ({
                    ...base,
                  }),
                  multiValue: (styles, { data }) => {
                    return {
                      ...styles,
                      borderRadius: 10,
                      backgroundColor: data.archive ? '#7239ea73  ' : '#432cffb8',
                      padding: 4,
                      margin: 4,
                      color: 'white',
                      boxShadow: '#0303030f 0px 0px 20px 0px'
                    };
                  },
                  multiValueLabel: (styles, { data }) => ({
                    ...styles,
                    fontWeight: 'bold',
                    color: 'white'

                  }),
                  multiValueRemove: (styles, { data }) => ({
                    ...styles,
                    ':hover': {
                      color: 'gray',
                    },
                  }),
                  control: (base) => ({
                    ...base,
                    // backgroundColor: var(--kt-input-solid-bg),
                    // zIndex: 9999,
                    padding: "5px",
                    border: "none",
                    borderRadius: 10,
                    boxShadow: '#3d88a614 0px 0px 20px 0px'
                    // boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px'
                    // boxShadow: "0 0 50px 0 rgb(82 63 105 / 15%)",
                  }),
                  menu: (base) => ({
                    ...base,
                    // zIndex: 19999,
                    backgroundColor: "white",
                    padding: "10px",
                    border: "none",
                    borderRadius: 10,
                    boxShadow: '#3d88a614 0px 0px 20px 0px'
                    // boxShadow: 'rgba(0, 0, 0, 0.07) -4px 9px 25px -6px'
                    // boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px',
                    // boxShadow: "0 0 50px 0 rgb(82 63 105 / 15%)",
                  }),
                  singleValue: (base) => ({ ...base, color: "#000", borderRadius: 10, }),
                }}

                formatOptionLabel={(option, { selectValue }) => {
                  const isSelected = selectValue && selectValue.some((selectedOption) => selectedOption.value === option.value);

                  return (
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-oval symbol-40px overflow-hidden me-3'>
                        <a href='#'>
                          {option.avatar ? (
                            <div className='symbol-label'>
                              <img src={option.avatar} alt={option.label} className='w-100' />
                            </div>
                          ) : (
                            <div
                              className={clsx(
                                'symbol-label fs-3',
                                `bg-light-${option.label}`,
                                `text-${option.label}`
                              )}
                            >
                              {option.label?.[0]}
                            </div>
                          )}
                        </a>
                      </div>
                      <div className='d-flex flex-column'>
                        <a href='#' className={isSelected ? 'text-white' : 'text-gray-800 text-hover-primary mb-1'}>
                          {option.label}
                        </a>
                        <span style={{ color: 'black' }}>{option.email}</span>
                      </div>
                    </div>
                  );
                }}
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                isSearchable={true}
                name="color"
                options={clients.filter((item, index) => {
                  if (
                    containsId(event.parent, item.value)
                  ) {
                    return false
                  }
                  else {
                    return true
                  }
                })}
              />
              {formik.touched.tags && formik.errors.tags && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.tags}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Input */}
          </div>

          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className=' fw-bold fs-6 mb-2'>Memory Makers</label>
            {/* end::Label */}

            {/* begin::Input */}
            <div
              className='px-2'
              onClick={(event) => {
                event.stopPropagation();
              }}>

              <ReactSelect
                // value={value}
                {...formik.getFieldProps('memoryMakers')}
                placeholder="Tap or Search"
                hideSelectedOptions={false}
                onChange={(newValue) => {
                  formik.setFieldValue('memoryMakers', newValue, false)
                }}
                backspaceRemovesValue={false}
                blurInputOnSelect={false}
                className=' fs-6'
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                  },
                })}

                isMulti={true}
                styles={{
                  option: (base, state) => ({
                    ...base,
                  }),
                  multiValue: (styles, { data }) => {
                    return {
                      ...styles,
                      borderRadius: 10,
                      backgroundColor: data.archive ? '#7239ea73  ' : '#432cffb8',
                      padding: 4,
                      margin: 4,
                      color: 'white',
                      boxShadow: '#0303030f 0px 0px 20px 0px'
                    };
                  },
                  multiValueLabel: (styles, { data }) => ({
                    ...styles,
                    fontWeight: 'bold',
                    color: 'white'

                  }),
                  multiValueRemove: (styles, { data }) => ({
                    ...styles,
                    ':hover': {
                      color: 'gray',
                    },
                  }),
                  control: (base) => ({
                    ...base,
                    // backgroundColor: var(--kt-input-solid-bg),
                    // zIndex: 9999,
                    padding: "5px",
                    border: "none",
                    borderRadius: 10,
                    boxShadow: '#3d88a614 0px 0px 20px 0px'
                    // boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px'
                    // boxShadow: "0 0 50px 0 rgb(82 63 105 / 15%)",
                  }),
                  menu: (base) => ({
                    ...base,
                    // zIndex: 19999,
                    backgroundColor: "white",
                    padding: "10px",
                    border: "none",
                    borderRadius: 10,
                    boxShadow: '#3d88a614 0px 0px 20px 0px'
                    // boxShadow: 'rgba(0, 0, 0, 0.07) -4px 9px 25px -6px'
                    // boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px',
                    // boxShadow: "0 0 50px 0 rgb(82 63 105 / 15%)",
                  }),
                  singleValue: (base) => ({ ...base, color: "#000", borderRadius: 10, }),
                }}

                formatOptionLabel={(option, { selectValue }) => {
                  const isSelected = selectValue && selectValue.some((selectedOption) => selectedOption.value === option.value);

                  return (
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-oval symbol-40px overflow-hidden me-3'>
                        <a href='#'>
                          {option.avatar ? (
                            <div className='symbol-label'>
                              <img src={option.avatar} alt={option.label} className='w-100' />
                            </div>
                          ) : (
                            <div
                              className={clsx(
                                'symbol-label fs-3',
                                `bg-light-${option.label}`,
                                `text-${option.label}`
                              )}
                            >
                              {option.label?.[0]}
                            </div>
                          )}
                        </a>
                      </div>
                      <div className='d-flex flex-column'>
                        <a href='#' className={isSelected ? 'text-white' : 'text-gray-800 text-hover-primary mb-1'}>
                          {option.label}
                        </a>
                        <span style={{ color: 'black' }}>{option.email}</span>
                      </div>
                    </div>
                  );
                }}
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                isSearchable={true}
                name="color"
                options={memoryMakers}
              />
              {formik.touched.tags && formik.errors.tags && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.tags}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Input */}
          </div>



          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Export Folder (CameraRoll)</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='KNVB_SAYCHEESE'
              {...formik.getFieldProps('exportFolder')}
              type='text'
              // defaultValue={("SC_Export_" + formik.values.projectNo + "_" + new Date(formik.values.startDate).toISOString().slice(0, -14)).toLocaleLowerCase()}
              name='exportFolder'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.exportFolder && formik.errors.exportFolder },
                {
                  'is-valid': formik.touched.exportFolder && !formik.errors.exportFolder,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isEventLoading}
            />
            {formik.touched.exportFolder && formik.errors.exportFolder && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.exportFolder}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className=' fw-bold fs-6 mb-2'>Templates</label>
            {/* end::Label */}

            {/* begin::Input */}
            <div
              className='px-2'
              onClick={(event) => {
                event.stopPropagation();
              }}>

              <ReactSelect
                // value={value}
                {...formik.getFieldProps('templates')}
                placeholder="Tap or Search"
                hideSelectedOptions={false}
                onChange={(newValue) => {
                  formik.setFieldValue('templates', newValue, false)
                }}
                filterOption={(option, rawInput) => {
                  let searchArray = [...option.data.tags];  // Use the spread operator to clone the array
                  searchArray.push(option.label.toLowerCase());
                  searchArray.push(option.value.toLowerCase());

                  const matchingStrings = searchArray.filter((str) => str.includes(rawInput));
                  return matchingStrings.length > 0;  // Simplified the return statement
                }
                  // customFilter(option, rawInput, option => option.value)
                }
                backspaceRemovesValue={false}
                blurInputOnSelect={false}
                className=' fs-6'
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                  },
                })}
                isMulti={true}
                styles={{
                  option: (base, state) => ({
                    ...base,
                  }),
                  multiValue: (styles, { data }) => {
                    return {
                      ...styles,
                      borderRadius: 10,
                      backgroundColor: data.archive ? '#7239ea73  ' : '#432cffb8',
                      padding: 4,
                      margin: 4,
                      color: 'white',
                      boxShadow: '#0303030f 0px 0px 20px 0px'
                    };
                  },
                  multiValueLabel: (styles, { data }) => ({
                    ...styles,
                    fontWeight: 'bold',
                    color: 'white'

                  }),
                  multiValueRemove: (styles, { data }) => ({
                    ...styles,
                    ':hover': {
                      color: 'gray',
                    },
                  }),
                  control: (base) => ({
                    ...base,
                    // backgroundColor: var(--kt-input-solid-bg),
                    // zIndex: 9999,
                    padding: "5px",
                    border: "none",
                    borderRadius: 10,
                    boxShadow: '#3d88a614 0px 0px 20px 0px'
                    // boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px'
                    // boxShadow: "0 0 50px 0 rgb(82 63 105 / 15%)",
                  }),
                  menu: (base) => ({
                    ...base,
                    // zIndex: 19999,
                    backgroundColor: "white",
                    padding: "10px",
                    border: "none",
                    borderRadius: 10,
                    boxShadow: '#3d88a614 0px 0px 20px 0px'
                    // boxShadow: 'rgba(0, 0, 0, 0.07) -4px 9px 25px -6px'
                    // boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px',
                    // boxShadow: "0 0 50px 0 rgb(82 63 105 / 15%)",
                  }),
                  singleValue: (base) => ({ ...base, color: "#000", borderRadius: 10, }),
                }}

                formatOptionLabel={(option, { selectValue }) => {
                  const isSelected = selectValue && selectValue.some((selectedOption) => selectedOption.value === option.value);
                  return (
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-oval symbol-40px overflow-hidden me-3'>
                        <a href='#'>
                          {option.avatar ? (
                            <div className='symbol-label'>
                              <img src={option.avatar} alt={option.label} className='w-100' />
                            </div>
                          ) : (
                            <div
                              className={clsx(
                                'symbol-label fs-3',
                                `bg-light-${option.label}`,
                                `text-${option.label}`
                              )}
                            >
                              {option.label?.[0]}
                            </div>
                          )}
                        </a>
                      </div>
                      <div className='d-flex flex-column'>
                        <a href='#' className={isSelected ? 'text-white' : 'text-gray-800 text-hover-primary mb-1'}>
                          {option.label}
                        </a>
                        <span style={{ color: 'black' }}>{option.email}</span>
                      </div>
                    </div>
                  );
                }}
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                isSearchable={true}
                name="color"
                options={templates}
              />
              {formik.touched.tags && formik.errors.tags && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.tags}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Input */}
          </div>


          <div className='mb-7'>
            <label className='required fw-bold fs-6 mb-5'>QR Cards</label>
            <div className='d-flex fv-row'>
              <RadioInput
                formik={formik}
                name='qrCards'
                label='Yes'
                value={true}
                id='kt_modal_update_role_option_0'
                isTemplateLoading={isEventLoading}
              />
            </div>
            <div className='separator separator-dashed my-5'></div>
            <div className='d-flex fv-row'>
              <RadioInput
                formik={formik}
                name='qrCards'
                label='No'
                value={false}
                id='kt_modal_update_role_option_1'
                isTemplateLoading={isEventLoading}
              />
            </div>
            <div className='separator separator-dashed my-5'></div>
          </div>

          <div className='mb-7'>
            <label className='required fw-bold fs-6 mb-5'>Form</label>
            <div className='d-flex fv-row'>
              <RadioInput
                formik={formik}
                name='isForm'
                label='Yes'
                value={true}
                id='kt_modal_update_role_option_0'
                isTemplateLoading={isEventLoading}
              />
            </div>
            <div className='separator separator-dashed my-5'></div>
            <div className='d-flex fv-row'>
              <RadioInput
                formik={formik}
                name='isForm'
                label='No'
                value={false}
                id='kt_modal_update_role_option_1'
                isTemplateLoading={isEventLoading}
              />
            </div>
            <div className='separator separator-dashed my-5'></div>
          </div>

          {
            formik.values.isForm && (
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Form</label>
                {/* end::Label */}

                {/* begin::Input */}
                <div className='input-group' style={{ zIndex: 0 }}>
                  <input
                    placeholder='https://yourstorywall.com/content/FRM'
                    {...formik.getFieldProps('form')}
                    type='text'
                    // defaultValue={("https://yourstorywall.com/content/FRM_" + formik.values.projectNo + "_" + removeSpaces(formik.values.parent[0].label) + "_" + formik.values.startDate.toISOString().slice(0, -14)).toLocaleLowerCase()}
                    name='form'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      { 'is-invalid': formik.touched.form && formik.errors.form },
                      { 'is-valid': formik.touched.form && !formik.errors.form }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting || isEventLoading}
                  />
                  <button
                    className='btn btn-primary'
                    type='button'
                    onClick={() => {
                      copyToClipboard(formik.values.form);
                      // Optionally, provide feedback to the user that the text has been copied.
                      // Example: alert('Copied to clipboard!');
                    }}
                    disabled={formik.isSubmitting || isEventLoading}
                  >
                    Copy
                  </button>
                </div>
                {/* end::Input */}

                {formik.touched.form && formik.errors.form && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.form}</span>
                    </div>
                  </div>
                )}
              </div>
            )
          }

          {
            formik.values.isForm && (
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>QR Mail Id</label>
                {/* end::Label */}

                {/* begin::Input */}
                <div className='input-group' style={{ zIndex: 0 }}>
                  <input
                    placeholder='Mail Id'
                    {...formik.getFieldProps('qrMail')}
                    type='text'
                    // defaultValue={("https://yourstorywall.com/content/FRM_" + formik.values.projectNo + "_" + removeSpaces(formik.values.parent[0].label) + "_" + formik.values.startDate.toISOString().slice(0, -14)).toLocaleLowerCase()}
                    name='qrMail'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      { 'is-invalid': formik.touched.qrMail && formik.errors.qrMail },
                      { 'is-valid': formik.touched.qrMail && !formik.errors.qrMail }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting || isEventLoading}
                  />
                  <button
                    className='btn btn-primary'
                    type='button'
                    onClick={() => {
                      copyToClipboard(formik.values.qrMail);
                      // Optionally, provide feedback to the user that the text has been copied.
                      // Example: alert('Copied to clipboard!');
                    }}
                    disabled={formik.isSubmitting || isEventLoading}
                  >
                    Copy
                  </button>
                </div>
                {/* end::Input */}

                {formik.touched.qrMail && formik.errors.qrMail && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.qrMail}</span>
                    </div>
                  </div>
                )}
              </div>
            )
          }

          <div className='mb-7'>
            <label className='required fw-bold fs-6 mb-5'>Info</label>
            <div className='d-flex fv-row'>
              <RadioInput
                formik={formik}
                name='isIntro'
                label='Yes'
                value={true}
                id='kt_modal_update_role_option_0'
                isTemplateLoading={isEventLoading}
              />
            </div>
            <div className='separator separator-dashed my-5'></div>
            <div className='d-flex fv-row'>
              <RadioInput
                formik={formik}
                name='isIntro'
                label='No'
                value={false}
                id='kt_modal_update_role_option_1'
                isTemplateLoading={isEventLoading}
              />
            </div>
            <div className='separator separator-dashed my-5'></div>
          </div>

          {
            formik.values.isIntro && (
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Info</label>
                {/* end::Label */}

                {/* begin::Input */}
                <div className='input-group' style={{ zIndex: 0 }}>
                  <input
                    placeholder='https://yourstorywall.com/content/FRM'
                    {...formik.getFieldProps('intro')}
                    type='text'
                    // defaultValue={("https://yourstorywall.com/content/FRM_" + formik.values.projectNo + "_" + removeSpaces(formik.values.parent[0].label) + "_" + formik.values.startDate.toISOString().slice(0, -14)).toLocaleLowerCase()}
                    name='intro'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      { 'is-invalid': formik.touched.intro && formik.errors.intro },
                      { 'is-valid': formik.touched.intro && !formik.errors.intro }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting || isEventLoading}
                  />
                  <button
                    className='btn btn-primary'
                    type='button'
                    onClick={() => {
                      copyToClipboard(formik.values.intro);
                      // Optionally, provide feedback to the user that the text has been copied.
                      // Example: alert('Copied to clipboard!');
                    }}
                    disabled={formik.isSubmitting || isEventLoading}
                  >
                    Copy
                  </button>
                </div>
                {/* end::Input */}

                {formik.touched.intro && formik.errors.intro && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.intro}</span>
                    </div>
                  </div>
                )}
              </div>
            )
          }


          <div className='mb-7'>
            <label className='required fw-bold fs-6 mb-5'>Mail</label>
            <div className='d-flex fv-row'>
              <RadioInput
                formik={formik}
                name='isMail'
                label='Yes'
                value={true}
                id='kt_modal_update_role_option_0'
                isTemplateLoading={isEventLoading}
              />
            </div>
            <div className='separator separator-dashed my-5'></div>
            <div className='d-flex fv-row'>
              <RadioInput
                formik={formik}
                name='isMail'
                label='No'
                value={false}
                id='kt_modal_update_role_option_1'
                isTemplateLoading={isEventLoading}
              />
            </div>
            <div className='separator separator-dashed my-5'></div>
          </div>

          {
            formik.values.isMail && (
              <>
                <div className='fv-row mb-7'>
                  {/* begin::Label */}
                  <label className='required fw-bold fs-6 mb-2'>Mail ID</label>
                  {/* end::Label */}

                  {/* begin::Input */}
                  <div className='input-group' style={{ zIndex: 0 }}>
                    <input
                      placeholder='https://yourstorywall.com/content/FRM'
                      {...formik.getFieldProps('sendgrid')}
                      type='text'
                      name='sendgrid'
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        { 'is-invalid': formik.touched.sendgrid && formik.errors.sendgrid },
                        { 'is-valid': formik.touched.sendgrid && !formik.errors.sendgrid }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting || isEventLoading}
                    />
                    <button
                      className='btn btn-primary'
                      type='button'
                      onClick={() => {
                        copyToClipboard(formik.values.sendgrid);
                        // Optionally, provide feedback to the user that the text has been copied.
                        // Example: alert('Copied to clipboard!');
                      }}
                      disabled={formik.isSubmitting || isEventLoading}
                    >
                      Copy
                    </button>
                  </div>
                  {/* end::Input */}

                  {formik.touched.sendgrid && formik.errors.sendgrid && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.sendgrid}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='fv-row mb-7'>
                  {/* begin::Label */}
                  <label className=' fw-bold fs-6 mb-2'>Body Copy Mail</label>
                  {/* end::Label */}
                  {/* begin::Input */}
                  <textarea
                    className='form-control form-control-solid'
                    rows={3}
                    placeholder='tagline/passage/paragraph for sending in mails'
                    {...formik.getFieldProps('mailParagraph')}
                    name='mailParagraph'
                    autoComplete='off'
                    disabled={formik.isSubmitting || isEventLoading}
                  />
                  {/* end::Input */}
                </div>
              </>
            )
          }

          {
            formik.values.isMail && (
              <>
                <div className='fv-row mb-7'>
                  {/* begin::Label */}
                  <label className='required fw-bold fs-6 mb-2'>Wix Mail ID</label>
                  {/* end::Label */}

                  {/* begin::Input */}
                  <div className='input-group' style={{ zIndex: 0 }}>
                    <input
                      placeholder='ID'
                      {...formik.getFieldProps('wixMail')}
                      type='text'
                      name='wixMail'
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        { 'is-invalid': formik.touched.wixMail && formik.errors.wixMail },
                        { 'is-valid': formik.touched.wixMail && !formik.errors.wixMail }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting || isEventLoading}
                    />
                    <button
                      className='btn btn-primary'
                      type='button'
                      onClick={() => {
                        copyToClipboard(formik.values.wixMail);
                        // Optionally, provide feedback to the user that the text has been copied.
                        // Example: alert('Copied to clipboard!');
                      }}
                      disabled={formik.isSubmitting || isEventLoading}
                    >
                      Copy
                    </button>
                  </div>
                  {/* end::Input */}

                  {formik.touched.wixMail && formik.errors.wixMail && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.wixMail}</span>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )
          }

          <div className='mb-7'>
            <label className='required fw-bold fs-6 mb-5'>Storywall Default</label>
            <div className='d-flex fv-row'>
              <RadioInput
                formik={formik}
                name='isMicrositeDefault'
                label='Yes'
                value={true}
                id='kt_modal_update_role_option_0'
                isTemplateLoading={isEventLoading}
              />
            </div>
            <div className='separator separator-dashed my-5'></div>
            <div className='d-flex fv-row'>
              <RadioInput
                formik={formik}
                name='isMicrositeDefault'
                label='No'
                value={false}
                id='kt_modal_update_role_option_1'
                isTemplateLoading={isEventLoading}
              />
            </div>
            <div className='separator separator-dashed my-5'></div>
          </div>

          {
            !formik.values.isMicrositeDefault &&

            <div className='mb-7'>
              <label className='required fw-bold fs-6 mb-5'>Storywall</label>
              <div className='d-flex fv-row'>
                <RadioInput
                  formik={formik}
                  name='isMicrosite'
                  label='Yes'
                  value={true}
                  id='kt_modal_update_role_option_0'
                  isTemplateLoading={isEventLoading}
                />
              </div>
              <div className='separator separator-dashed my-5'></div>
              <div className='d-flex fv-row'>
                <RadioInput
                  formik={formik}
                  name='isMicrosite'
                  label='No'
                  value={false}
                  id='kt_modal_update_role_option_1'
                  isTemplateLoading={isEventLoading}
                />
              </div>
              <div className='separator separator-dashed my-5'></div>
            </div>
          }

          {
            formik.values.isMicrosite && !formik.values.isMicrositeDefault &&

            <>

              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Name on Storywall</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Activation Title'
                  {...formik.getFieldProps('micrositeName')}
                  value={formik.values.micrositeName}
                  type='text'
                  name='micrositeName'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.micrositeName && formik.errors.micrositeName },
                    {
                      'is-valid': formik.touched.micrositeName && !formik.errors.micrositeName,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isEventLoading}
                />
                {formik.touched.micrositeName && formik.errors.micrositeName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.micrositeName}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
              {/* end::Input group */}

              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>Date on Storywall</label>
                <DatePicker showPopperArrow={false}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.micrositeDate && formik.errors.micrositeDate },
                    {
                      'is-valid': formik.touched.micrositeDate && !formik.errors.micrositeDate,
                    }
                  )}
                  selected={formik.values.micrositeDate ? formik.values.micrositeDate : formik.values.startDate}
                  onChange={(micrositeDate) => {
                    formik.setFieldValue('micrositeDate', micrositeDate, false)
                  }}
                  showTimeSelect={false}
                  // timeFormat="HH:mm"
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              {/* end::Input group */}

              <div className='fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>Development Url</label>
                <div className='input-group ' style={{ zIndex: 0 }}>
                  <input
                    placeholder='https://yourstorywall.com/content/dev'
                    {...formik.getFieldProps('micrositeBeta')}
                    type='text'
                    name='micrositeBeta'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      { 'is-invalid': formik.touched.micrositeBeta && formik.errors.micrositeBeta },
                      { 'is-valid': formik.touched.micrositeBeta && !formik.errors.micrositeBeta }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting || isEventLoading}
                  />
                  <button
                    className='btn btn-primary'
                    type='button'
                    onClick={() => {
                      copyToClipboard(formik.values.micrositeBeta);
                      // Optionally, provide feedback to the user that the text has been copied.
                      // Example: alert('Copied to clipboard!');
                    }}
                    disabled={formik.isSubmitting || isEventLoading}
                  >
                    Copy
                  </button>
                </div>
                {formik.touched.micrositeBeta && formik.errors.micrositeBeta && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.micrositeBeta}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Production Url (fka Router)</label>
                {/* end::Label */}

                {/* begin::Input */}
                <div className='input-group' style={{ zIndex: 0 }}>
                  <input
                    placeholder='March 23 knvb'
                    {...formik.getFieldProps('microsite')}
                    type='text'
                    name='microsite'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      { 'is-invalid': formik.touched.microsite && formik.errors.microsite },
                      { 'is-valid': formik.touched.microsite && !formik.errors.microsite }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting || isEventLoading}
                  />
                  <button
                    className='btn btn-primary'
                    type='button'
                    onClick={() => {
                      copyToClipboard(formik.values.microsite);
                      // Optionally, provide feedback to the user that the text has been copied.
                      // Example: alert('Copied to clipboard!');
                    }}
                    disabled={formik.isSubmitting || isEventLoading}
                  >
                    Copy
                  </button>
                </div>
                {/* end::Input */}

                {formik.touched.microsite && formik.errors.microsite && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.microsite}</span>
                    </div>
                  </div>
                )}
              </div>

              {


                <div className='mb-7'>
                  <label className='required fw-bold fs-6 mb-5'>Custom Router</label>
                  <div className='d-flex fv-row'>
                    <RadioInput
                      formik={formik}
                      name='isRouter'
                      label='Yes'
                      value={true}
                      id='kt_modal_update_role_option_0'
                      isTemplateLoading={isEventLoading}
                    />
                  </div>
                  <div className='separator separator-dashed my-5'></div>
                  <div className='d-flex fv-row'>
                    <RadioInput
                      formik={formik}
                      name='isRouter'
                      label='No'
                      value={false}
                      id='kt_modal_update_role_option_1'
                      isTemplateLoading={isEventLoading}
                    />
                  </div>
                  <div className='separator separator-dashed my-5'></div>
                </div>
              }

              {
                formik.values.isRouter &&
                <>
                  <div className='fv-row mb-7'>
                    {/* begin::Label */}
                    <label className='required fw-bold fs-6 mb-2'>Router</label>
                    {/* end::Label */}

                    {/* begin::Input */}
                    <div className='input-group' style={{ zIndex: 0 }}>
                      <input
                        placeholder='Default'
                        {...formik.getFieldProps('router')}
                        type='text'
                        name='router'
                        className={clsx(
                          'form-control form-control-solid mb-3 mb-lg-0',
                          { 'is-invalid': formik.touched.router && formik.errors.router },
                          { 'is-valid': formik.touched.router && !formik.errors.router }
                        )}
                        autoComplete='off'
                        disabled={formik.isSubmitting || isEventLoading}
                      />
                      <button
                        className='btn btn-primary'
                        type='button'
                        onClick={() => {
                          copyToClipboard(formik.values.router);
                          // Optionally, provide feedback to the user that the text has been copied.
                          // Example: alert('Copied to clipboard!');
                        }}
                        disabled={formik.isSubmitting || isEventLoading}
                      >
                        Copy
                      </button>
                    </div>
                    {/* end::Input */}

                    {formik.touched.router && formik.errors.router && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.router}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              }




              {/* end::Input group */}
            </>
          }
          <div className='mb-7'>
            <label className='required fw-bold fs-6 mb-5'>DOOH</label>
            <div className='d-flex fv-row'>
              <RadioInput
                formik={formik}
                name='isDooh'
                label='Yes'
                value={true}
                id='kt_modal_update_role_option_0'
                isTemplateLoading={isEventLoading}
              />
            </div>
            <div className='separator separator-dashed my-5'></div>
            <div className='d-flex fv-row'>
              <RadioInput
                formik={formik}
                name='isDooh'
                label='No'
                value={false}
                id='kt_modal_update_role_option_1'
                isTemplateLoading={isEventLoading}
              />
            </div>
            <div className='separator separator-dashed my-5'></div>
          </div>
          {
            formik.values.isDooh &&

            <>




              {/* end::Input group */}

              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>DOOH Link</label>
                <div className='input-group' style={{ zIndex: 0 }}>
                  <input
                    placeholder='https://yourstorywall.com/content/'
                    {...formik.getFieldProps('dooh')}
                    type='text'
                    name='dooh'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      { 'is-invalid': formik.touched.dooh && formik.errors.dooh },
                      { 'is-valid': formik.touched.dooh && !formik.errors.dooh }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting || isEventLoading}
                  />
                  <button
                    className='btn btn-primary'
                    type='button'
                    onClick={() => {
                      copyToClipboard(formik.values.dooh);
                      // Optionally, provide feedback to the user that the text has been copied.
                      // Example: alert('Copied to clipboard!');
                    }}
                    disabled={formik.isSubmitting || isEventLoading}
                  >
                    Copy
                  </button>
                </div>
                {formik.touched.dooh && formik.errors.dooh && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.dooh}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Input group */}

              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Quit Claim</label>
                {/* end::Label */}

                {/* begin::Input */}
                <div className='input-group' style={{ zIndex: 0 }}>
                  <input
                    placeholder='https://yourstorywall.com/content/'
                    {...formik.getFieldProps('quitclaim')}
                    type='text'
                    name='quitclaim'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      { 'is-invalid': formik.touched.quitclaim && formik.errors.quitclaim },
                      { 'is-valid': formik.touched.quitclaim && !formik.errors.quitclaim },
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting || isEventLoading}
                  />
                  <button
                    className='btn btn-primary'
                    type='button'
                    onClick={() => {
                      copyToClipboard(formik.values.quitclaim);
                      // Optionally, provide feedback to the user that the text has been copied.
                      // Example: alert('Copied to clipboard!');
                    }}
                    disabled={formik.isSubmitting || isEventLoading || !formik.values.quitclaim}
                  >
                    Copy
                  </button>
                </div>
                {/* end::Input */}

                {formik.touched.quitclaim && formik.errors.quitclaim && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.quitclaim}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Input group */}

              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Mail ID</label>
                {/* end::Label */}

                {/* begin::Input */}
                <div className='input-group' style={{ zIndex: 0 }}>
                  <input
                    placeholder='https://yourstorywall.com/content/FRM'
                    {...formik.getFieldProps('sendgridDooh')}
                    type='text'
                    name='sendgridDooh'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      { 'is-invalid': formik.touched.sendgridDooh && formik.errors.sendgridDooh },
                      { 'is-valid': formik.touched.sendgridDooh && !formik.errors.sendgridDooh }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting || isEventLoading}
                  />
                  <button
                    className='btn btn-primary'
                    type='button'
                    onClick={() => {
                      copyToClipboard(formik.values.sendgridDooh);
                      // Optionally, provide feedback to the user that the text has been copied.
                      // Example: alert('Copied to clipboard!');
                    }}
                    disabled={formik.isSubmitting || isEventLoading}
                  >
                    Copy
                  </button>
                </div>
                {/* end::Input */}

                {formik.touched.sendgridDooh && formik.errors.sendgridDooh && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.sendgridDooh}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className=' fw-bold fs-6 mb-2'>Body Copy Mail</label>
                {/* end::Label */}
                {/* begin::Input */}
                <textarea
                  className='form-control form-control-solid '
                  rows={3}
                  placeholder='tagline/passage/paragraph for sending in mails'
                  {...formik.getFieldProps('mailParagraphDooh')}
                  name='mailParagraphDooh'
                  autoComplete='off'
                  disabled={formik.isSubmitting || isEventLoading}
                ></textarea>
                {/* end::Input */}
              </div>

            </>
          }





          <div className='mb-7'>
            <label className='required fw-bold fs-6 mb-5'>Analytics</label>
            <div className='d-flex fv-row'>
              <RadioInput
                formik={formik}
                name='isAnalytics'
                label='Yes'
                value={true}
                id='kt_modal_update_role_option_0'
                isTemplateLoading={isEventLoading}
              />
            </div>
            <div className='separator separator-dashed my-5'></div>
            <div className='d-flex fv-row'>
              <RadioInput
                formik={formik}
                name='isAnalytics'
                label='No'
                value={false}
                id='kt_modal_update_role_option_1'
                isTemplateLoading={isEventLoading}
              />
            </div>
            <div className='separator separator-dashed my-5'></div>
          </div>

          {
            formik.values.isAnalytics &&

            <div className='fv-row mb-7'>
              {/* begin::Label */}
              <label className=' fw-bold fs-6 mb-2'>Embed Code (Looker Studio Report)</label>
              {/* end::Label */}
              {/* begin::Input */}
              <textarea
                className='form-control form-control-solid mb-8'
                rows={1}
                placeholder='tagline/passage/paragraph for sending in mails'
                {...formik.getFieldProps('embedCode')}
                name='embedCode'
                autoComplete='off'
                disabled={formik.isSubmitting || isEventLoading}
              ></textarea>
              {/* end::Input */}
            </div>
          }



        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-events-modal-action='cancel'
            disabled={formik.isSubmitting || isEventLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-events-modal-action='submit'
            disabled={isEventLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isEventLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form >
      {(formik.isSubmitting || isEventLoading) && <EventsListLoading />
      }
    </>
  )
}

export { EventEditModalForm }
