import { FC, useState } from 'react'
import CreatableSelect from 'react-select/creatable';
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { navigateToModal } from '../../../../../../jsFunctions';
import "react-datepicker/dist/react-datepicker.css"
import { isNotEmpty, toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { initialClient, Client } from '../core/_models'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { ClientsListLoading } from '../components/loading/ClientsListLoading'
import { createClient, updateClient, uploadAndUpdate } from '../core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { convertArrToSelect } from '../../../../../../jsFunctions';
import { copyToClipboard, getDateTime } from '../../../../../../jsFunctions';

type Props = {
  isClientLoading: boolean
  client: Client
}

const editClientSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
})


interface DropdownOptions {
  readonly value: string;
  readonly label: string;
  readonly color: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

const dropdownOptions: readonly DropdownOptions[] = [
  { value: 'client', label: 'client', color: '#00B8D9', isFixed: true },
  { value: 'SAYCHEESE', label: 'SAYCHEESE', color: 'red', isDisabled: true },
];

const ClientEditModalForm: FC<Props> = ({ client, isClientLoading }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()


  const [clientForEdit] = useState<Client>({
    ...client,
    avatar: client.avatar || initialClient.avatar,
    info: client.info || initialClient.info,
    name: client.name || initialClient.name,
    tags: client.tags ? convertArrToSelect(client.tags) : dropdownOptions
  })


  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
  const clientAvatarImg = toAbsoluteUrl(`/media/${clientForEdit.avatar}`)

  const [avatar, setAvatar] = useState<any>(clientForEdit.avatar);

  const handleChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<any> => {
    // const { target } = event;
    // const img = new Image();

    const ImageName = event.target.value.split('\\')[2];
    const Image = event.target.value;
    console.log('Imageon Kevel: ', Image); // Image on Kevel
    console.log('ImageName on Kevel: ', ImageName); // ImageName on Kevel
    console.log('ImageLink on Kevel: ', event.target.value); // ImageLink on Kevel
    // console.log('event current Target files: ', event.target?.files?[0].path);

    if (event?.target?.files?.[0]!) {
      setAvatar({ display: URL.createObjectURL(event?.target?.files?.[0]), upload: event?.target?.files?.[0] });
    }
  };

  const formik = useFormik({
    initialValues: clientForEdit,
    validationSchema: editClientSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateClient({ ...values })
          if (avatar.upload && values.id) {
            uploadAndUpdate(values.id, avatar.upload, values.id)
          }
        } else {
          await createClient({ ...values, avatar: avatar.upload })
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_client_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_client_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_client_header'
          data-kt-scroll-wrappers='#kt_modal_add_client_scroll'
          data-kt-scroll-offset='300px'
        >


          {/* begin::Input group */}

          {
            clientForEdit.id &&
            <>
              {/* begin::Switch */}
              <div className='mb-7 d-flex justify-content-between align-items-center'>
                <div className='fw-bold fs-6'>De-activate</div>
                <div className='form-check form-check-custom form-check-solid form-switch'>
                  <input
                    className='form-check-input'
                    {...formik.getFieldProps('deactivate')}
                    type='checkbox'
                    id='consent'
                    checked={formik.values.deactivate}
                    onChange={() => formik.setFieldValue('deactivate', !formik.values.deactivate)}
                    disabled={isClientLoading}
                  />
                </div>
              </div>
              {/* end::Switch */}

              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Creation Date</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Creation Date'
                  {...formik.getFieldProps('dateCreated')}
                  type='text'
                  name='name'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.name && formik.errors.name },
                    {
                      'is-valid': formik.touched.name && !formik.errors.name,
                    }
                  )}
                  autoComplete='off'
                  disabled={true}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.name}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
              {/* end::Input group */}

              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Last Modified</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Creation Date'
                  {...formik.getFieldProps('updatedOn')}
                  type='text'
                  name='updatedOn'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.updatedOn && formik.errors.updatedOn },
                    {
                      'is-valid': formik.touched.updatedOn && !formik.errors.updatedOn,
                    }
                  )}
                  autoComplete='off'
                  disabled={true}
                />
                {formik.touched.updatedOn && formik.errors.updatedOn && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.updatedOn}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
              {/* end::Input group */}
            </>
          }

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='d-block fw-bold fs-6 mb-5'>Avatar</label>
            {/* end::Label */}
            {/* begin::Image input */}
            <div
              // className='image-input image-input-outline'
              className='image-input'
              data-kt-image-input='true'
              style={{ backgroundImage: `url('${blankImg}')` }}
            >
              {/* begin::Preview existing avatar */}
              <div
                className='image-input-wrapper w-125px h-125px'
                style={{ backgroundImage: `url('${avatar.display ? avatar.display : clientForEdit.avatar}')` }}
              />
              <label
                className='btn btn-icon  btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='change'
                data-bs-toggle='tooltip'
                title='Change avatar'
              >
                <i className='bi bi-pencil-fill fs-7'></i>

                <input type='file'
                  onChange={handleChange}
                  name='avatar' accept='.png, .jpg, .jpeg' />
              </label>

              {/* end::Label */}
              {/* end::Remove */}
            </div>
            {/* end::Image input */}

            {/* begin::Hint */}
            {/* <div className='form-text'>Allowed file types: png, jpg, jpeg.</div> */}
            {/* end::Hint */}
          </div>
          {/* end::Input group */}

          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Client Name</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Brand name'
              {...formik.getFieldProps('name')}
              type='text'
              name='name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.name && formik.errors.name },
                {
                  'is-valid': formik.touched.name && !formik.errors.name,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isClientLoading}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-2'>Tags</label>
            {/* end::Label */}

            {/* begin::Input */}
            {/* <input
              placeholder='Tags'
              {...formik.getFieldProps('tags')}
              type='string'
              name='tags'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.tags && formik.errors.tags },
                {
                  'is-valid': formik.touched.tags && !formik.errors.tags,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isClientLoading}
            />
            {formik.touched.tags && formik.errors.tags && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.tags}</span>
                </div>
              </div>
            )} */}
            {/* <div className='mb-10'> */}
            {/* <label className='form-label fw-bold'>Tags:</label> */}
            <div
              className='px-2'
              onClick={(event) => {
                event.stopPropagation();
              }}>

              <CreatableSelect
                // value={value}
                {...formik.getFieldProps('tags')}
                placeholder="Type to Choose or Create"
                hideSelectedOptions={false}
                onChange={(newValue) => {

                  formik.setFieldValue('tags', newValue, false)
                  // setValue(newValue)
                  // let arr = newValue.map((item) => item.value)
                  // console.log(arr)
                  // updateData({ tags: arr })
                }}
                backspaceRemovesValue={false}
                blurInputOnSelect={false}
                className='text-uppercase fs-8'
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                  },
                })}
                isMulti={true}
                styles={{
                  option: (base, state) => ({
                    ...base,
                  }),
                  multiValue: (styles, { data }) => {
                    return {
                      ...styles,
                      borderRadius: 10,
                      backgroundColor: '#432cffb8',
                      color: 'white',
                      boxShadow: '#0303030f 0px 0px 20px 0px'
                    };
                  },
                  multiValueLabel: (styles, { data }) => ({
                    ...styles,
                    fontWeight: 'bold',
                    color: 'white'

                  }),
                  multiValueRemove: (styles, { data }) => ({
                    ...styles,
                    ':hover': {
                      color: 'gray',
                    },
                  }),
                  control: (base) => ({
                    ...base,
                    // backgroundColor: var(--kt-input-solid-bg),
                    // zIndex: 9999,
                    padding: "5px",
                    border: "none",
                    borderRadius: 10,
                    boxShadow: '#3d88a614 0px 0px 20px 0px'
                    // boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px'
                    // boxShadow: "0 0 50px 0 rgb(82 63 105 / 15%)",
                  }),
                  menu: (base) => ({
                    ...base,
                    // zIndex: 19999,
                    backgroundColor: "white",
                    padding: "10px",
                    border: "none",
                    borderRadius: 10,
                    boxShadow: '#3d88a614 0px 0px 20px 0px'
                    // boxShadow: 'rgba(0, 0, 0, 0.07) -4px 9px 25px -6px'
                    // boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px',
                    // boxShadow: "0 0 50px 0 rgb(82 63 105 / 15%)",
                  }),
                  singleValue: (base) => ({ ...base, color: "#000", borderRadius: 10, }),
                }}
                // defaultValue={[dropdownOptions[2], dropdownOptions[3]]}
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                isSearchable={true}
                name="color"
                options={dropdownOptions}
              />
              {/* {!data.tags.length && hasError && (
              <div className='fv-plugins-message-container'>
                <div data-field='name' data-validator='notEmpty' className='fv-help-block'>
                  Tags required
                </div>
              </div>
            )} */}
              {formik.touched.tags && formik.errors.tags && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.tags}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Input */}
          </div>

          {/* begin::Input group */}
          {/* <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Date</label>
            <DatePicker showPopperArrow={false}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.date && formik.errors.date },
                {
                  'is-valid': formik.touched.date && !formik.errors.date,
                }
              )}
              minDate={new Date()}
              selected={date}
              onChange={(date) => {
                formik.setFieldValue('date', date, false)
                setDate(date)
              }}
              showTimeSelect={false}
              dateFormat={"MMMM d, yyyy"}

            />
          </div> */}
          {/* end::Input group */}

          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className=' fw-bold fs-6 mb-2'>Notes</label>
            {/* end::Label */}

            {/* begin::Input */}


            <textarea
              className='form-control form-control-solid mb-8'
              rows={3}
              placeholder='Add extra info here'
              {...formik.getFieldProps('info')}
              name='info'
              autoComplete='off'
              disabled={formik.isSubmitting || isClientLoading}
            ></textarea>
            {/* end::Input */}
          </div>

          {
            clientForEdit.id &&
            <>
              <div className='card-header border-0 mb-7'>
                <h3 className='card-title fw-bold text-dark fs-6'>Users</h3>
              </div>
              {
                clientForEdit.id && clientForEdit.users.length > 0 && (
                  <>
                    {clientForEdit.users.map((users: any, index: number) => (
                      <div className='fv-row mb-3' onClick={() => window.open("https://dashboard.saycheese.photo/", '_blank')}>
                        <div className='d-flex align-items-center mb-7'>
                          {/* begin::Avatar */}
                          <div className='symbol symbol-50px me-5'>
                            <img src={users.avatar ? users.avatar : blankImg} className='' alt='' />
                          </div>
                          {/* end::Avatar */}
                          {/* begin::Text */}
                          <div className='flex-grow-1'>
                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                              {users.label}
                            </a>
                            <span className='text-muted d-block fw-semibold fs-6'>
                              {getDateTime(users.data.dateCreated.seconds * 1000)}
                            </span>
                            <span className='text-muted d-block fw-semibold mt-3'>
                              Activated: {users.data.deactivate ? "No" : 'Yes'}
                            </span>
                          </div>

                          {/* end::Text */}
                          {/* Copy Button */}
                          <button
                            className='btn btn-info btn-sm'
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent event propagation
                              e.preventDefault(); // Prevent the form from being submitted
                              copyToClipboard(users.value);
                            }}
                          >
                            Copy
                          </button>
                        </div>
                      </div>
                    ))}
                  </>
                )
              }

              <div className='card-header border-0 mb-7'>
                <h3 className='card-title fw-bold text-dark fs-6'>Activations</h3>
              </div>
              {
                clientForEdit.activations.length > 0 && (
                  <>
                    {clientForEdit.activations.map((activation: any, index: number) => (
                      <a href='#' className='fv-row mb-3' onClick={() => navigateToModal("event", activation.id)}>
                        <div className='d-flex align-items-center mb-7'>
                          {/* begin::Avatar */}
                          <div className='symbol symbol-50px me-5' >
                            <img src={activation.avatar ? activation.avatar : blankImg} className='' alt='' />
                          </div>
                          {/* end::Avatar */}
                          {/* begin::Text */}
                          <div className='flex-grow-1'>
                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                              {activation.label}
                            </a>
                            <span className='text-muted d-block fw-semibold fs-6'>
                              {getDateTime(activation.data.startDate.seconds * 1000)}
                            </span>
                            <span className='text-muted d-block fw-semibold mt-3'>
                              DOOH: {activation.data.isDooh ? activation.data.dooh : 'Disabled'}
                            </span>
                            <span className='text-muted d-block fw-semibold mt-3'>
                              SW: {activation.data.isMicrosite ? activation.data.microsite : 'Disabled'}
                            </span>
                          </div>

                          {/* end::Text */}
                          {/* Copy Button */}
                          <button
                            className='btn btn-info btn-sm'
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent event propagation
                              e.preventDefault(); // Prevent the form from being submitted
                              copyToClipboard(activation.value);
                            }}
                          >
                            Copy
                          </button>
                        </div>
                      </a>
                    ))}
                  </>
                )
              }

              <div className='card-header border-0 mb-7'>
                <h3 className='card-title fw-bold text-dark fs-6'>Shared Activations</h3>
              </div>
              {
                clientForEdit.shared_activations.length > 0 && (
                  <>
                    {clientForEdit.shared_activations.map((activation: any, index: number) => (
                      <a href='#' className='fv-row mb-3' onClick={() => navigateToModal("event", activation.id)}>
                        <div className='d-flex align-items-center mb-7'>
                          {/* begin::Avatar */}
                          <div className='symbol symbol-50px me-5' >
                            <img src={activation.avatar ? activation.avatar : blankImg} className='' alt='' />
                          </div>
                          {/* end::Avatar */}
                          {/* begin::Text */}
                          <div className='flex-grow-1'>
                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                              {activation.label}
                            </a>
                            <span className='text-muted d-block fw-semibold fs-6'>
                              {getDateTime(activation.data.startDate.seconds * 1000)}
                            </span>
                            <span className='text-muted d-block fw-semibold mt-3'>
                              DOOH: {activation.data.isDooh ? activation.data.dooh : 'Disabled'}
                            </span>
                            <span className='text-muted d-block fw-semibold mt-3'>
                              SW: {activation.data.isMicrosite ? activation.data.microsite : 'Disabled'}
                            </span>
                          </div>

                          {/* end::Text */}
                          {/* Copy Button */}
                          <button
                            className='btn btn-info btn-sm'
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent event propagation
                              e.preventDefault(); // Prevent the form from being submitted
                              copyToClipboard(activation.value);
                            }}
                          >
                            Copy
                          </button>
                        </div>
                      </a>
                    ))}
                  </>
                )
              }

              <div className='card-header border-0 mb-5'>
                <h3 className='card-title fw-bold text-dark fs-6'>Collections</h3>
              </div>

              {
                clientForEdit.collections.length > 0 && (
                  <>

                    {clientForEdit.collections.map((collection: any, index: number) => (
                      <div className='fv-row mb-3'>
                        {/* begin::Label */}
                        {/* end::Label */}
                        <div className='d-flex align-items-center mb-7'>
                          {/* begin::Avatar */}
                          <div className='symbol symbol-50px me-5' onClick={() => navigateToModal("campaign", collection.id)}>
                            <img src={collection.avatar ? collection.avatar : blankImg} className='' alt='' />
                          </div>
                          {/* end::Avatar */}
                          {/* begin::Text */}
                          <div className='flex-grow-1'>
                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                              {collection.label}
                            </a>
                            <span className='text-muted d-block fw-semibold'>
                              {getDateTime(collection.data.startDate.seconds * 1000)}
                            </span>
                          </div>
                          {/* end::Text */}
                          {/* Copy Button */}
                          <button
                            className='btn btn-info btn-sm'
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent event propagation
                              e.preventDefault(); // Prevent the form from being submitted
                              copyToClipboard(collection.value);
                            }}
                          >
                            Copy
                          </button>
                        </div>
                      </div>
                    ))}
                  </>
                )
              }
            </>
          }



        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-clients-modal-action='cancel'
            disabled={formik.isSubmitting || isClientLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-clients-modal-action='submit'
            disabled={isClientLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isClientLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isClientLoading) && <ClientsListLoading />}
    </>
  )
}

export { ClientEditModalForm }
