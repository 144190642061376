/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components';
import { ID, KTSVG, QUERIES } from '../../../../../../../_metronic/helpers';
import { useListView } from '../../core/ListViewProvider';
import { useQueryResponse } from '../../core/QueryResponseProvider';
import { archiveEvent } from '../../core/_requests';
import { isURL, duplicateDocument } from '../../../../../../../jsFunctions';

type Props = {
  id: ID;
  embedCode?: any;
  eventName?: string;
  activation?: string;  // Add this prop to pass activation ID
};

const EventActionsCell: FC<Props> = ({ id, embedCode, eventName, activation }) => {
  const { setItemIdForUpdate, setItemIdForDelete } = useListView();
  const { query } = useQueryResponse();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  useEffect(() => {
    MenuComponent.reinitialization();
  }, []);

  const openEditModal = () => {
    setItemIdForUpdate(id);
  };

  const duplicate = useMutation(() => duplicateDocument(id, "_activations"), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.EVENTS_LIST}-${query}`]);
    },
  });

  const deleteItem = useMutation(() => archiveEvent(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.EVENTS_LIST}-${query}`]);
    },
  });

  const handleButtonClick = () => {
    if (isURL(embedCode)) {
      const url = `/apps/event-management/${eventName}/report?link=${embedCode}&name=${eventName}`;
      window.open(url, '_blank');
    } else {
      alert("No report available.");
    }
  };

  // Function to download report in CSV format
  // Function to download report in CSV format
const downloadReport = async () => {
  try {
    const response = await fetch(`https://yourstorywall.com/_functions/getFormReportForActivation?activation=${id}`);
    const result = await response.json();
    console.log(result);

    // Check if `data` is available in the response
    if (!result.data || result.data.length === 0) {
      alert('No data available for this report.');
      return;
    }

    // Filter data to include only items where formField is true
    const filteredData = result.data.filter((item: any) => item.formField === true);

    // Check if filteredData has any items to export
    if (filteredData.length === 0) {
      alert('No data with formField set to true.');
      return;
    }

    // Get the current date and time for report generation
    const reportDate = new Date();
    const formattedDate = reportDate.toLocaleDateString('en-GB'); // Format as "dd/mm/yyyy"
    const formattedTime = reportDate.toLocaleTimeString('en-GB'); // Format as "hh:mm:ss"
    const timestamp = `${formattedDate} ${formattedTime}`;

    // Add the timestamp to each row in the filtered data
    const dataWithTimestamp = filteredData.map((item: any) => ({
      ...item,
      reportGeneratedAt: timestamp,
    }));

    // Convert JSON data with timestamp to CSV, showing only email, firstName, lastName, and reportGeneratedAt
    const csvRows = [];
    const headers = ['email', 'firstName', 'lastName', 'reportGeneratedAt'];
    csvRows.push(headers.join(',')); // Add header row

    dataWithTimestamp.forEach((row: any) => {
      const values = headers.map(header => JSON.stringify(row[header] ?? '')); // Handle missing fields
      csvRows.push(values.join(','));
    });

    const csvContent = csvRows.join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    // Format filename with date and time
    const fileName = `report_${id}_${formattedDate.replace(/\//g, '-')}_${formattedTime.replace(/:/g, '-')}.csv`;

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    link.click();
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading report:', error);
    alert('Failed to download report.');
  }
};

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            Edit
          </a>
        </div>

        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={handleButtonClick}>
            Studio Report
          </a>
        </div>

        {/* New Menu item for downloading CSV report */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={downloadReport}>
            Wix Report
          </a>
        </div>

        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-events-table-filter='delete_row'
            onClick={() => setItemIdForDelete(id)}
          >
            Archive
          </a>
        </div>
      </div>
    </>
  );
};

export { EventActionsCell };