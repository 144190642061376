import { collection, getDocs, getFirestore, where, query, getDoc, doc, addDoc, setDoc, Timestamp, updateDoc } from 'firebase/firestore';
import moment from 'moment';

const axios = require('axios');

// export const getDropdownOptionsFromFirebase = async (collectionName, ids, ownerId, memoryMakers, templates) => {

//   const db = getFirestore();
//   const owner = [];

//   if (ownerId) {
//     const docRef = doc(db, '_clients', ownerId);
//     getDoc(docRef)
//       .then((docSnapshot) => {
//         if (docSnapshot.exists()) {
//           const ownerData = docSnapshot.data()
//           owner.push({
//             value: ownerData.id,
//             avatar: ownerData.avatar,
//             label: ownerData.name,
//             id: ownerData.id,
//             isFixed: ownerData.isFixed || false,
//             isDisabled: ownerData.isDisabled || false,
//             startDate: ownerData.startDate || null,
//           })
//         }
//       })
//       .catch((error) => {
//         console.error('Error getting owner:', error);
//       });
//   }

//   if (!ids || !ids.length) {
//     return { parent: [], clients: [], owner };
//   }

//   try {
//     // Get a Firestore reference
//     // Create an array to store the dropdown options
//     const dropdownOptions = [];
//     const clients = [];

//     // Create a Firestore query to fetch documents from the specified collection
//     const q = query(collection(db, collectionName), where('id', 'in', ids));

//     // Fetch documents based on the query
//     const querySnapshot = await getDocs(q);

//     querySnapshot.forEach((doc) => {
//       const data = doc.data();

//       // Map the document data to dropdownOptions
//       const dropdownOption = {
//         value: data.id,
//         avatar: data.avatar,
//         label: data.name,
//         id: data.id,
//         isFixed: data.isFixed || false,
//         isDisabled: data.isDisabled || false,
//         startDate: data.startDate || null,
//       };

//       dropdownOptions.push(dropdownOption);
//     });

//     return { parent: dropdownOptions, clients, owner };
//   } catch (error) {
//     console.error('Error fetching data from Firebase:', error);
//     throw error;
//   }
// }


export const updateZearchArray = async () => {
  const db = getFirestore();
  const templatesCollection = collection(db, '_activations');

  try {
    // Fetch all documents from _templates collection
    const templatesSnapshot = await getDocs(templatesCollection);

    // Array to store names

    // Loop through each document
    templatesSnapshot.forEach((doc) => {
      // Get the name field from the document (assuming 'name' is the field you want to search)

      const name = doc.data().name.toLowerCase();
      const projectNo = doc.data().projectNo.toLowerCase();

      // Add name to the array
      // Update the zearch array in the document with the new name
      const docRef = doc.ref;

      let obj = {}

      if (doc.data().tags?.length) {
        doc.data().tags.forEach((item) => {
          obj[item] = item
        });
      }

      obj[name] = name
      obj[projectNo] = projectNo

      console.log("updateZearchArray")

      updateDoc(docRef, {
        zearch: Array.from(new Set(simpleSubsequencesForObject(obj).concat([doc.id.toLowerCase()])))//simpleSubsequencesForObject({"name": name}).concat([doc.id]),
      });
    });


  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

async function fetchDocs(ids, collectionName, paramId) {
  if (!ids || isArrayOnlyUndefinedOrNull(ids) || !Array.isArray(ids) || !ids.length) {
    return [];
  }

  const db = getFirestore();
  const originalCollection = collection(db, collectionName);

  // Split ids into chunks of 30
  const chunkedIds = [];
  for (let i = 0; i < ids.length; i += 30) {
    chunkedIds.push(ids.slice(i, i + 30));
  }

  const fetchedDocs = [];
  try {
    // Fetch documents in chunks
    for (const chunk of chunkedIds) {
      const q = query(originalCollection, where(paramId, 'in', chunk));
      const querySnapshot = await getDocs(q);
      
      fetchedDocs.push(...querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          value: data.id || doc.id,
          avatar: data.avatar || data.image || "",
          label: collectionName === '_users' ? data.name : data.email || data.name,
          id: data.id || doc.id,
          isFixed: data.isFixed || false,
          isDisabled: data.isDisabled || false,
          startDate: data.startDate ? new Date(data.startDate.seconds * 1000) : null,
          archive: false,
          data: data
        };
      }));
    }

    // Check for missing documents and fetch from the archive if needed
    const missingIds = ids.filter((id) => !fetchedDocs.some((doc) => doc.id === id));
    if (missingIds.length > 0) {
      const archiveDocs = await fetchFromArchive(db, missingIds, collectionName, paramId);
      return fetchedDocs.concat(archiveDocs);
    }
    return fetchedDocs;
  } catch (error) {
    console.error('Error fetching data from Firebase:', error);
    throw error;
  }
}

async function fetchFromArchive(db, missingIds, collectionName, paramId) {
  const archiveCollectionName = `archive${collectionName}`;
  const archiveDocs = [];

  // Split missing IDs into chunks of 30
  for (let i = 0; i < missingIds.length; i += 30) {
    const chunk = missingIds.slice(i, i + 30);
    const archiveQ = query(collection(db, archiveCollectionName), where(paramId, 'in', chunk));
    const archiveQuerySnapshot = await getDocs(archiveQ);

    archiveDocs.push(...archiveQuerySnapshot.docs.map((archiveDoc) => {
      const archiveData = archiveDoc.data();
      return {
        value: archiveData.id || archiveDoc.id,
        avatar: archiveData.avatar || "",
        label: archiveData.name,
        id: archiveData.id || archiveDoc.id,
        isFixed: archiveData.isFixed || false,
        isDisabled: archiveData.isDisabled || false,
        startDate: archiveData.startDate ? new Date(archiveData.startDate.seconds * 1000) : null,
        archive: true
      };
    }));
  }
  return archiveDocs;
}

// async function fetchDocs(ids, collectionName, paramId) {

//   if (!ids || isArrayOnlyUndefinedOrNull(ids)) {
//     return []
//   }

//   if (!Array.isArray(ids) || !ids.length) {
//     return [];
//   }

//   console.log(paramId + " " + collectionName + " " + ids + " " + Array.isArray(ids) + " " + ids.length)

//   const db = getFirestore();
//   const originalCollection = collection(db, collectionName);

//   const q = query(originalCollection, where(paramId, 'in', ids));

//   try {
//     const querySnapshot = await getDocs(q);

//     // console.log(querySnapshot.size + " " + ids)

//     const fetchedDocs = querySnapshot.docs.map((doc) => {
//       const data = doc.data();

//       return {
//         value: data.id ? data.id : doc.id,
//         avatar: data.avatar ? data.avatar : data.image ? data.image : "",
//         label: collectionName === '_users' ? data.name : data.email ? data.email : data.name,
//         id: data.id,
//         isFixed: data.isFixed || false,
//         isDisabled: data.isDisabled || false,
//         startDate: data.startDate ? new Date(data.startDate.seconds * 1000) : null,
//         archive: false, // Default value for the archive flag
//         data: data
//       };
//     });

//     // Check which documents were not returned in the request
//     const missingIds = ids.filter((id) => !fetchedDocs.some((doc) => doc.id === id));

//     // Fetch missing documents from the archive collection
//     if (missingIds.length > 0) {
//       // console.log("missingIds are ", missingIds + " \n\n " + `archive${collectionName}`)
//       // console.log(`archive${collectionName}`)
//       const archiveCollectionName = `archive${collectionName}`;
//       const archiveQ = query(collection(db, archiveCollectionName), where(paramId, 'in', missingIds));
//       const archiveQuerySnapshot = await getDocs(archiveQ);

//       const archiveDocs = archiveQuerySnapshot.docs.map((archiveDoc) => {
//         const archiveData = archiveDoc.data();
      
//         if (archiveData.id) {
//           return {
//             value: archiveData.id || archiveDoc.id,
//             avatar: archiveData.avatar,
//             label: archiveData.name,
//             id: archiveData.id || archiveDoc.id,
//             isFixed: archiveData.isFixed || false,
//             isDisabled: archiveData.isDisabled || false,
//             startDate: archiveData.startDate ? new Date(archiveData.startDate.seconds * 1000) : null,
//             archive: true, // Indicate that this document is from the archive
//           };
//         }
//         return null; // Ensure to return null if the condition is not met
//       }).filter(doc => doc !== null); // Filter out any null values
      
//       // Combine fetchedDocs with archiveDocs
//       const allDocs = fetchedDocs.concat(archiveDocs);

//       console.log("allDocs ", allDocs)

//       return allDocs;
//     } else {
//       return fetchedDocs;
//     }
//   } catch (error) {
//     console.error('Error fetching data from Firebase:', error);
//     throw error;
//   }
// }

export async function duplicateDocument(originalId, collectionName) {
  const db = getFirestore();
  const originalDocRef = doc(db, collectionName, originalId);

  try {
    const originalDocSnapshot = await getDoc(originalDocRef);

    if (originalDocSnapshot.exists()) {
      const newDoc = doc(collection(db, collectionName));

      const originalData = originalDocSnapshot.data();

      if (originalData.name) {
        originalData.name = originalData.name + " (copy)";
      }

      if (originalData.dateCreated) {
        originalData.dateCreated = Timestamp.now()
      }

      if (originalData.updatedOn) {
        originalData.updatedOn = Timestamp.now()
      }

      originalData.id = newDoc.id;

      // Create a new document with a dynamically generated ID
      const newDocRef = await setDoc(newDoc, originalData);

      console.log(`Document duplicated successfully from ${originalId} to ${originalData.id}`);

      // Return success
      return { success: true, message: `Document duplicated successfully from ${originalId} to ${originalData.id}` };
    } else {
      console.error(`Document with ID ${originalId} does not exist`);
      // Return an error message
      return { success: false, message: `Document with ID ${originalId} does not exist` };
    }
  } catch (error) {
    console.error('Error duplicating document:', error);
    // Return an error message
    return { success: false, message: 'Error duplicating document', error: error };
  }
}


// Example usage:
// const originalId = 'yourOriginalDocumentId';
// const collectionName = 'yourCollectionName';

// const result = await duplicateDocument(originalId, collectionName);
// console.log(result);

export async function getDocsFromCollection(paramId, collectionName, id) {
  try {
    const [docsArray] = await Promise.all([
      fetchDocs([id], collectionName, paramId),
    ]);

    return { docsArray };
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export async function getDropdownOptionsFromFirebase(paramId, collectionName, ids, ownerId, memoryMakers1, templates1, activationId,) {
  try {
    const [owner, clients, memoryMakers, templates, associatedCollections] = await Promise.all([
      fetchDocs([ownerId], '_clients', "id"),
      fetchDocs(ids, collectionName, paramId),
      fetchDocs(memoryMakers1, "_users", "id"),
      fetchDocs(templates1, "_templates", "id"),
      getAssociatedDocs(activationId)
    ]);

    return { parent: clients, owner, memoryMakers, templates, associatedCollections };
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export const checkForBool = (value, isString) => {
  if (value == "true" || value == true) {
    if (isString) {
      return "true"
    }
    return true
  }
  else if (value == "false" || value == false) {
    if (isString) {
      return "false"
    }
    return false
  }
  else if (value == "N/A") {
    if (isString) {
      return "N/A"
    }
    return null
  }
  else {
    return value
  }
}

export const getAssociatedDocs = async (activationId, collectionName, paramId, operator) => {
  const db = getFirestore();

  if (!operator) {
    operator = "array-contains"
  }

  if (!activationId) {
    return
  }

  if (!collectionName) {
    collectionName = "_collections"
  }

  if (!paramId) {
    paramId = "events"
  }


  const q = query(collection(db, collectionName), where(paramId, operator, activationId));

  try {
    const querySnapshot = await getDocs(q);

    const fetchedDocs = querySnapshot.docs.map((doc) => {
      const data = doc.data();
      return {
        value: data.id,
        avatar: data.avatar ? data.avatar : data.image ? data.image : "",
        label: data.email ? data.email : data.name,
        id: data.id,
        isFixed: data.isFixed || false,
        isDisabled: data.isDisabled || false,
        startDate: data.startDate ? new Date(data.startDate.seconds * 1000) : null,
        archive: false, // Default value for the archive flag
        data: data
      };
    });

    return fetchedDocs;

  } catch (error) {
    console.error('Error fetching data from Firebase:', error);
    throw error;
  }
};


export const removeEmptyAttributes = (obj) => {
  //hunnain implement this tech to other requests file

  for (const key in obj) {
    const value = obj[key];
    if (value === null || value === undefined || value === '') {
      delete obj[key];
    } else if (typeof value === 'string') {
      // Convert 'true' to true and 'false' to false
      if (value.toLowerCase() === 'true') {
        obj[key] = true;
      } else if (value.toLowerCase() === 'false') {
        obj[key] = false;
      }
    }
  }
  return obj;
};

export const addRemoveArr = (oldArr, obj) => {
  const existingIndex = oldArr.findIndex((item) => item.id === obj.id);

  if (existingIndex !== -1) {
    // If the object exists, remove it from the array
    oldArr.splice(existingIndex, 1);
  } else {
    // If the object doesn't exist, add it to the array
    oldArr.push(obj);
  }

  console.log(oldArr)

  return oldArr
}

export const objInArr = (oldArr, id) => {

  const existingIndex = oldArr.findIndex((item) => item.id === id);

  if (existingIndex !== -1) {
    // If the object exists,
    console.log(true)
    return true
  } else {
    // If the object doesn't exist,
    console.log(false)
    return false
  }
}

export const addDaysToDate = (inputDate, daysToAdd) => {
  const date = new Date(inputDate);
  date.setDate(date.getDate() + daysToAdd);
  return date;
}

export const findFirstAndLastDates = (data) => {
  console.log(data)
  if (data.length === 0) {
    return { startDate: null, endDate: null };
  }

  // Sort the array of objects based on the startDate property
  data.sort((a, b) => a.startDate - b.startDate);

  console.log("data[0].startDate) ", data[0].startDate)

  // The first date is the startDate of the first object
  const startDate = data[0].startDate;


  // The last date is the startDate of the last object
  const lastDate = data[data.length - 1].startDate;
  const endDate = addDaysToDate(lastDate, 1)

  return { startDate, endDate };
}

export const complexSubsequencesForObject = (obj) => {
  const subsequencesArray = [];
  for (const key in obj) {
    const value = obj[key];
    const len = value.length;
    for (let i = 1; i <= len; i++) {
      for (let j = 0; j <= len - i; j++) {
        subsequencesArray.push(value.slice(j, j + i));
      }
    }
  }
  return subsequencesArray;
}

// export const simpleSubsequencesForObject = (obj) => {
//   //hunnain handle parent array
//   const subsequencesArray = [];

//   for (const key in obj) {
//     const value = obj[key];
//     let subsequence = '';
//     for (const char of value) {
//       subsequence += char;
//       subsequencesArray.push(subsequence);
//     }
//   }

//   return subsequencesArray;
// }

export const simpleSubsequencesForObject = (obj) => {
  const subsequencesArray = [];

  for (const key in obj) {
    const value = obj[key].toLowerCase(); // Convert to lowercase
    let subsequence = '';
    for (const char of value) {
      subsequence += char;
      subsequencesArray.push(subsequence);
    }
  }

  return subsequencesArray;
};


function isUndefinedOrNull(value) {
  return value === undefined || value === null;
}

function isArrayOnlyUndefinedOrNull(arr) {
  return Array.isArray(arr) && arr.every(isUndefinedOrNull);
}

export const getDateTime = (time, minutes, flag) => {
  time = new Date(time);
  // console.log("time: ", time);

  // return "" + moment(new Date(time).toLocaleString()).format('HH:mm MMMM d, yyyy')
  return (new Date(time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }) + "  -  " + moment(new Date(time)).format('DD/MM/YYYY'))
}

export const getDate = (time, minutes, flag) => {
  time = new Date(time);

  return moment(new Date(time)).format('HH:mm DD/MM/YYYY')
}

export const dateDownloadFormat = (time) => {
  time = new Date(time);

  return moment(new Date(time)).format('DDMMYYYY')
}


export const stringToDate = (time, minutes, flag) => {
  time = new Date(time);
  console.log("time: ", time);
  return moment(time).format('DD/MM/YYYY')
}


export const format = (n) => {
  var val = Math.round(Number(n) * 100) / 100;
  var parts = val.toString().split(".");
  return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
}

export const getTime = (time) => {

  return (new Date(time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }))//.substring(0,5)

}

export const convertArrToSelect = (arr) => {
  return arr.map((item, index) => { return ({ 'label': item, 'value': item }) })
}

export const convertSelectToArr = (arr, allowUperCases) => {
  return arr.map((item) => allowUperCases ? item.value : item.value.toLowerCase());
}

export const checkIfIndexRequired = (error) => {
  const indexLinkRegex = /You can create it here: (https:\/\/.*?\/indexes\?.*?)/;
  const indexLinkMatch = error.match(indexLinkRegex);
  if (indexLinkMatch) {
    const indexLink = indexLinkMatch[1];
    alert("Index Required.")
    // window.open(indexLink, "_blank");
  }
}

export const getBoolean = (value, isString) => {
  if (value === true || value === 'true') {
    if (isString) {
      return 'true'
    }
    else {
      return true
    }

  }
  else {
    if (isString) {
      return 'false'
    }
    else {
      return false
    }

  }
}


export function RadioInput({
  formik,
  name,
  label,
  value,
  id,
  isTemplateLoading,
}) {
  return (
    <div className='form-check form-check-custom form-check-solid'>
      <input
        className='form-check-input me-3'
        {...formik.getFieldProps(name)}
        onChange={() => {
          formik.setFieldValue(name, value, false);
          if (name === 'type' && value === 'sticker') {
            formik.setFieldValue("microsite", false)
            formik.setFieldValue("print", false)
          }
        }}
        name={name}
        type='radio'
        value={value}
        id={id}
        checked={formik.values[name] === value}
        disabled={formik.isSubmitting || isTemplateLoading}
      />
      <label className='form-check-label' htmlFor={id}>
        <div className='fw-bolder text-gray-800'>{label}</div>
      </label>
    </div>
  );
}

export const isURL = (str) => {
  // Regular expression for a basic URL pattern
  const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/i;
  return urlRegex.test(str);
}


export const openIframeInNewTab = (link) => {
  // const newTab = window.open('', '_blank');
  // if (newTab) {
  //   newTab.document.body.innerHTML = `<iframe width="100%" height="100%" src=${link}></iframe>`;
  // } else {
  //   alert('Please allow pop-ups for this site to open the iframe in a new tab.');
  // }


  const newTab = window.open('', '_blank');
  if (newTab) {
    newTab.document.body.innerHTML = `<iframe width="100%" height="100%" src=${link}></iframe>`;

    // Update the URL in the address bar (cosmetic, won't change actual content)
    newTab.history.pushState({}, '', 'https://example.com');
  } else {
    alert('Please allow pop-ups for this site to open the iframe in a new tab.');
  }


};

export function containsId(array, id) {
  return array.some(item => item.id === id);
}

export function removeSpaces(str, dash) {
  // return str.replace(/ /g, dash ? dash : '_');
  str = str.trim();

  // Replace spaces with dashes
  str = str.replace(/ /g, dash ? dash : '_');

  return str;
}

export const copyToClipboard = (text) => {
  const textarea = document.createElement('textarea');
  textarea.value = text;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand('copy');
  document.body.removeChild(textarea);
};

export const downloadFile = (imageUrl, filename, video) => {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', imageUrl, true);
  xhr.responseType = 'blob';

  xhr.onload = () => {
    const blob = xhr.response;
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename ? filename : "saycheese_download" + video ? '.mp4' : '.png'; // You can customize the downloaded file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  xhr.send();
};


export const navigateToModal = (page, id) => {
  window.open(`https://saycheese.web.app//apps/${page}-management/${page}s?edit=${id}`)
}

// Function to get the size of an image stored in Firebase
export async function getImageSize(imageUrl) {
  return 0
}